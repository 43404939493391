// Deps
import axios from 'axios';
import { pushMessage } from 'controllers/messenger'
import config from 'config'
import store from 'store'

const defaultConfig = {
	excludeApiPath: false,
	dummy: false,
	showMessages: ['error', 'success', 'warning'],
};

export default {
	get: function (target, data = false, finalFunction = false, opts = {}) {
		return makeRequest('get', target, data, finalFunction, opts);
	},
	post: function (target, data = false, finalFunction = false, opts = {}) {
		var method = opts.dummy ? 'get' : 'post';
		return makeRequest(method, target, data, finalFunction, opts);
	},
	delete: function (target, data = false, finalFunction = false, opts = {}) {
		var method = opts.dummy ? 'get' : 'delete';
		return makeRequest(method, target, data, finalFunction, opts);
	}
}

function makeRequest(method, target, data = false, finalFunction = false, opts = {}) {
	// if(config.dummyApi && method === 'post') {
	// 	method = 'get'
	// }
	if (data === false) { data = {}; }
	let config = {
		...defaultConfig,
		...opts,
		...(opts.showMessages === false ? {
			showMessages: []
		} : {}),
	};

	let ajaxToken = axios.CancelToken;
	let ajaxController = ajaxToken.source();

	if (!config.excludeApiPath) {
		target = apiPath(target, config.dummy);
	}

	let requestParams = {
		method: method,
		url: target,
		cancelToken: ajaxController.token,
		headers: headData()
	}

	if(method === "get") {
		requestParams.params = data;
	}
	else {
		requestParams.data = data;
	}

	axios(requestParams).then(response => {
		evaluateData(response, finalFunction, config);
	}).catch((error) => {
		evaluateData(error.response ? error.response : {status: 500, data: { error: error }}, finalFunction, config)
	});

	return ajaxController;
}

function evaluateData(response, finalFunction = false, config) {
	let hasMessage = false;

	if(response.data.meta && response.data.meta.messages){
		for(const msgType in response.data.meta.messages){
			if((config.showMessages || []).includes(msgType)) {
				for(const message of response.data.meta.messages[msgType]){
					pushMessage(message, { type: msgType });
					hasMessage = true;
				};
			}
		};
	}

	switch (response.status) {
		case 200:
		case 201:
			if (finalFunction) {
				finalFunction(response.data.payload, response.status, response);
			}
			break;
		case 500:
		default:
			if(!hasMessage && (config.showMessages || []).includes('error')) {
				pushMessage("Hata: İşlem başarısız.", { type: "error" });
			}
			if (finalFunction) {
				finalFunction(false, response.status, response);
			}
			break;

	}
}

function apiPath(target, dummy) {
	return !dummy ?
		(config.apiURL + target) :
		('/dummy/api/' + target + '.json');
}

function headData() {
	const curState = store.getState();

	return {
		'Content-Type': 'application/json',
		'Authorization': (curState.user.token ? ('Bearer ' + curState.user.token) : false),
		'store_id': ((curState.user.data && curState.user.data.store_id) ? curState.user.data.store_id : false),
		//'email': (curState.user.data && curState.user.data.email) ? curState.user.data.email : false,
	}
}