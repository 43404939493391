import React, { useState, useEffect } from 'react';

// Partials
import Link from 'views/partials/link'
// import Btn from 'views/partials/btn'

// Deps
import siteServices from 'services/site'

const Dashboard = () => {
	const [notifications, setNotifications] = useState({});

	useEffect(() => {
		siteServices.getDashboard().then((payload) => {
			setNotifications(payload)
		});
	}, [])

	return (
		<div className="section dashboard">
			<div className="wrapper">
				<nav className="dashboard-nav">
					<div className="nav-bignav">
						<Link
							className="bignav-link"
							href="collectionList">
							{(notifications.collection > 0) && 
								<span className="link-notification">{notifications.collection}</span>
							}
							<i className="link-icon icon-nav-collection" />
							<strong>Toplama İşlemleri</strong>
						</Link>
						<Link
							className="bignav-link"
							href="planningList">
							{(notifications.planning > 0) && 
								<span className="link-notification">{notifications.planning}</span>
							}
							<i className="link-icon icon-nav-slots" />
							<strong>Kontrol İşlemleri</strong>
						</Link>
						<Link
							className="bignav-link"
							href="shipmentList">
							{(notifications.shipment > 0) && 
								<span className="link-notification">{notifications.shipment}</span>
							}
							<i className="link-icon icon-nav-shipment" />
							<strong>Sevkiyat İşlemleri</strong>
						</Link>
						<Link
							className="bignav-link"
							href="loadList">
							{(notifications.loading > 0) && 
								<span className="link-notification">{notifications.loading}</span>
							}
							<i className="link-icon icon-nav-load" />
							<strong>Yükleme İşlemleri</strong>
						</Link>
						<Link
							className="bignav-link"
							href="deliveryList">
							{(notifications.delivery > 0) &&
								<span className="link-notification">{notifications.delivery}</span>
							}
							<i className="link-icon icon-nav-delivery" />
							<strong>Teslimat İşlemleri</strong>
						</Link>
						<Link
							className="bignav-link"
							href="returnList">
							{(notifications.return > 0) &&
								<span className="link-notification">{notifications.return}</span>
							}
							<i className="link-icon icon-nav-return" />
							<strong>İade İşlemleri</strong>
						</Link>
					</div>

					{/* <div className="nav-controls">
						<Btn
							tag="link"
							block
							className="controls-link"
							href="performance" />
					</div> */}
				</nav>
			</div>
		</div>
	)
}

export default Dashboard;