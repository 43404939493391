import React from 'react';

// Partials
//import Link from 'views/partials/link'
import Btn from 'views/partials/btn'

const NotFound = () => {
	return (
		<div className="section notfound">
			<h1 className="notfound-title">Sayfa Bulunamadı</h1>

			<Btn
				tag="link"
				href="dashboard">
				Anasayfaya Git
			</Btn>
		</div>
	);
}

export default NotFound;