import React, { useState, useMemo } from 'react'
import config from 'config'

// Partials
import { FormInput } from 'views/partials/forms'
import Btn from 'views/partials/btn'

// Deps
import { connect } from "react-redux"
import cloneDeep from 'lodash/cloneDeep'

// Functions
import formatNumber from 'functions/format-number'

const mapStateToProps = state => {
	return {
		terminalDevice: state.site.terminalDevice
	};
};

const ProductCollectionOptsModal = ({ product, mode, onModify, className, closeBtn }) => {
	const [amount, setAmount] = useState(product.quantity_type === 2 ? 0.1 : 1);

	const { availableAmount, excessableAmount } = useMemo(() => {
		let available = 0;

		switch(mode){
			case "collection":
				available = (product.quantity.total -  (product.quantity.collected + product.quantity.unavailable + product.quantity.packed));
			break;
			case "collected":
				available = product.quantity.collected;
			break;
			case "unavailable":
				available = product.quantity.unavailable;
			break;
			default:
			break;
		}

		return {
			availableAmount: parseFloat(available.toFixed(config.quantityDecimals)),
			excessableAmount: product.product_erp_code === config.bagProductErpCode ? 100 : parseFloat(((mode === 'collection' && product.to_be_processed && product.prepare_status === 1) ? (available * config.excessibleAmountMultiplier) : available).toFixed(config.quantityDecimals)),
		};
	}, [product, mode]);

	const collectAction = (type = 'collect') => {
		if(onModify && amount > 0) {

			let newProduct = cloneDeep(product);
			let increment = parseFloat(amount);

			if(increment <= excessableAmount) {
				switch(type) {
					case 'unavailable':
						newProduct.quantity.unavailable = parseFloat((mode === 'unavailable' ? newProduct.quantity.unavailable - increment : newProduct.quantity.unavailable + increment).toFixed(config.quantityDecimals));
					break;
					default:
						newProduct.quantity.collected = parseFloat((mode === 'collection' ? (newProduct.quantity.collected + increment) : (newProduct.quantity.collected - increment)).toFixed(config.quantityDecimals));
					break;
				}

				onModify(newProduct);
			}
		}
	}

	return (
		<div className={className}>
			{closeBtn}
			<div className="modal-innercontent">
				<div className="quantity-product">
					<strong className="product-title">{product.product_name}</strong>
					<p className="product-quantity">
						<strong>Kalan:</strong> <span>{product.quantity_type === 2 ? formatNumber(availableAmount, { decimals: config.quantityDecimals }) : availableAmount} {product.quantity_type_text}</span>
					</p>
				</div>
				<div className="quantity-controls">
					<FormInput
						className="controls-counter"
						type="counter"
						value={amount}
						onChange={setAmount}
						decimals={product.quantity_type === 2}
						max={excessableAmount} />

					{mode === 'unavailable' ?
						<Btn
							className="controls-button error"
							onClick={() => { collectAction('unavailable'); }}>
							Bulunamayanlardan Çıkar
						</Btn>
						:
						<>
							{mode === 'collection' ?
								<>
									<Btn
										className="controls-button success x2"
										onClick={() => { collectAction(); }}>
										Topla
									</Btn>
									<Btn
										className="controls-button error"
										onClick={() => { collectAction('unavailable'); }}>
										Bulunamayan Ekle
									</Btn>
								</>
								:
								<Btn 
									className="controls-button success x2"
									onClick={() => { collectAction(); }}>
									Toplananlardan Çıkar
								</Btn>
							}
						</>
					}
				</div>
			</div>
		</div>
	)
}

const ConnectedModal = connect(mapStateToProps)(ProductCollectionOptsModal);

ConnectedModal.defaultProps = {
	className: "",
	containerClass: "modal-product-quantity",
	name: "productCollectionOpts",
	product: false,
	mode: 'collection',
}

export default ConnectedModal;