import React, { useState, useEffect, useMemo } from 'react'

// Deps
import formatDate from 'functions/format-date'
import { openModal } from 'utils/modals'

// Partials
import Btn from 'views/partials/btn'
import Loader from 'views/partials/loader'

// Services
import saleServices from 'services/sale'

const ShipmentList = () => {
	const [submitting, setSubmitting] = useState(false);
	const [sales, setSales] = useState(null);
	const [selectedSaleIDs, setSelectedSaleIDs] = useState([]);

	const addSaleToList = (sale) => {
		setSelectedSaleIDs([
			...selectedSaleIDs,
			sale.id
		])
	}

	const removeSaleFromList = (sale) => {
		setSelectedSaleIDs([
			...(selectedSaleIDs.filter(s => s !== sale.id)),
		])
	}

	const getSales = () => {
		setSales(null);
		saleServices.findShipmentSale().then((payload) => {
			setSelectedSaleIDs([]);
			setSales(payload.sales);
		}).catch(() => {
			setSales(false);
		});
	}

	const submitShipmentSales = () => {
		if(selectedSaleIDs.length) {
			openModal('confirm', {
				message: 'Sevkiyatı tamamlamak istediğinizden emin misiniz?', onConfirm: () => {
					setSubmitting(true);
					saleServices.submitShipmentList(selectedSaleIDs).then(() => {
						getSales();
						setSubmitting(false);
					}).catch(() => {
						getSales();
						setSubmitting(false);
					})
				}
			})
		}
	}

	const selectGroupSaleIDs = (group) => {
		let newSelectedIDs = [...selectedSaleIDs];
		const preSelected = group.sales.reduce((inc, sale) => {
			return [...inc, ...newSelectedIDs.includes(sale.id) ? [sale.id] : []];
		}, []);

		const availableGroupSales = group.sales.filter(s => s.status !== 61);

		if(preSelected.length === availableGroupSales.length) {
			newSelectedIDs = newSelectedIDs.filter(id => !preSelected.includes(id));
		}
		else {
			newSelectedIDs = [...newSelectedIDs, ...availableGroupSales.filter(s => !preSelected.includes(s.id)).map(s => s.id)];
		}

		setSelectedSaleIDs(newSelectedIDs);
	}

	const saleGroups = useMemo(() => {
		if(!sales) { return false; }

		const groups = {};

		for(const sale of sales) {
			const groupKey = sale.delivery_calendar_hour + sale.delivery_calendar_date;
			if(groups[groupKey]) {
				groups[groupKey].push(sale);
			}
			else {
				groups[groupKey] = [sale]
			};
		}

		return Object.keys(groups).map((groupKey) => {
			const groupSales = groups[groupKey];
			return {
				key: groupKey,
				title: groupSales[0].delivery_calendar_hour,
				sales: groupSales,
			}
		})
	}, [sales])

	useEffect(() => {
		getSales();
	}, [])

	return (
		<>
			<div className="section sale-search-results loader-container">
				<div className="wrapper">
					<Loader inner loading={saleGroups === null} />
					{!!saleGroups &&
						<>
							{saleGroups.length > 0 ?
								<ul className="listing">
									{saleGroups.map((group) => (
										<li className="listing-group" key={group.key}>
											<div className="group-head">
												<strong>{group.title}</strong>
												<Btn
													disabled={submitting}
													onClick={() => { selectGroupSaleIDs(group) }}
													className="primary"
													small low>
													Tümünü Seç
												</Btn>
											</div>
											{group.sales.map((sale) => (
												<div className="listing-item" key={sale.id}>
													<div className="item-field">
														<strong>Satış Kodu</strong>
														<span>{sale.sale_code}</span>
													</div>
													<div className="item-field">
														<strong>Müşteri Adı</strong>
														<span>{sale.sale_client}</span>
													</div>
													<div className="item-field">
														<strong>Durum</strong>
														<span>{sale.status_text}</span>
													</div>
													<div className="item-field">
														<strong>Ödeme Yöntemi</strong>
														<span>{sale.sale_payment_name}</span>
													</div>
													<div className="item-field">
														<strong>Tutar</strong>
														<span>{sale.total} TL</span>
													</div>
													<div className="item-field">
														<strong>Teslimat Saati</strong>
														<span className="date">
															<span>{formatDate(sale.delivery_calendar_date, 'DD MMMM YYYY')} - {sale.delivery_calendar_hour}</span>
														</span>
													</div>
													<div className="item-controls">
														{sale.status === 61 ?
															<Btn
																disabled
																low
																className="success">
																Planlanıyor
															</Btn>
															:
															<>
																{selectedSaleIDs.includes(sale.id)?
																	<Btn
																		disabled={submitting}
																		onClick={() => { removeSaleFromList(sale) }}
																		className="error"
																		low>
																		Sevk Listesinden Çıkar
																	</Btn>
																	:
																	<Btn
																		disabled={submitting}
																		onClick={() => { addSaleToList(sale) }}
																		low>
																		Sevk Listesine Ekle
																	</Btn>
																}
															</>
														}
													</div>
												</div>
											))}
										</li>
									))}
								</ul>
								:
								<div className="listing-message">
									Sevkiyat bekleyen sipariş yok.
								</div>
							}
						</>
					}
				</div>
			</div>

			<div className="section sale-controls">
				<Btn
					onClick={submitShipmentSales}
					disabled={submitting || selectedSaleIDs.length === 0}
					className={'controls-btn wide'}>
					Siparişleri Sevk Et
				</Btn>
			</div>
		</>
	)
}

export default ShipmentList