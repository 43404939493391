import React from 'react';
import config from 'config';

// Functions
import getCdnImage from 'functions/get-cdn-image'

// Partials
import Btn from 'views/partials/btn'
import Image from 'views/partials/image'

// Sections
import BarcodeInput from 'views/sections/barcode-input'

// Deps
import formatNumber from 'functions/format-number'
import cloneDeep from 'lodash/cloneDeep'
import isExact from 'functions/is-exact'
import { redirect } from 'controllers/navigator'
import { openModal, closeModal } from 'utils/modals'

export default class Collection extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			saleID: false,
			products: false,
			incompleteProducts: [],
			completeProducts: [],
			unavailableProducts: [],
			erpCodeIndex: {},
		}

		this.processProducts = this.processProducts.bind(this);
		this.completeCollection = this.completeCollection.bind(this);
		this.barcodeEntered = this.barcodeEntered.bind(this);
		//this.collectProduct = this.collectProduct.bind(this);
		this.openProductModal = this.openProductModal.bind(this);
		this.updateProduct = this.updateProduct.bind(this);
	}

	componentDidMount() {
		let vm = this;

		if(vm.props.sale){
			let products = vm.props.sale.product_groups;
			let saleID = vm.props.sale.id;
			
			if(localStorage.getItem('terminal-collection-cache') !== null) {
				let cache = JSON.parse(localStorage.getItem('terminal-collection-cache'));

				if(cache.type === 'collection' && cache.id === vm.props.sale.id) {
					products = cache.products;
					saleID = cache.id;
				}
				else {
					redirect('collectionDetail', {id: cache.id});
				}
			}
			vm.setState({ products: this.processProductGroups(products), saleID: saleID});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if(!isExact(prevState.products, this.state.products)){
			this.processProducts();
	
			if(!isExact(this.props.sale.product_groups, this.state.products)){
				let cacheData = JSON.stringify({
					id: this.state.saleID,
					type: 'collection',
					products: this.state.products,
				});

				localStorage.setItem('terminal-collection-cache', cacheData);
			}
		}
		else if(!isExact(prevProps.sale.product_groups, this.props.sale.product_groups)){
			if(prevProps.sale.id === this.props.sale.id) {
				const newSale = cloneDeep(this.props.sale);
				for(let groupNth = 0; groupNth < newSale.product_groups.length; groupNth++) {
					for(let prodNth = 0; prodNth < newSale.product_groups[groupNth].products.length; prodNth++) {
						const oldProduct = this.state.products[groupNth].products[prodNth];
						const newProduct = newSale.product_groups[groupNth].products[prodNth];

						if(newProduct.product_serialid === oldProduct.product_serialid) {
							newProduct.quantity = oldProduct.quantity;
						}
					}
				}

				this.setState({ products: this.processProductGroups(newSale.product_groups) });
			}
			else {
				this.setState({ products: this.processProductGroups(this.props.sale.product_groups), saleID: this.props.sale.id })
			}
		}
	}

	processProductGroups(productGroups) {
		return productGroups.map(group => {
			return {
				...group,
				products: group.products.map(product => {
					if(product.product_erp_code === config.deliveryProductErpCode) {
						return {
							...product,
							quantity: {
								...product.quantity,
								collected: product.quantity.total,
								unavailable: 0,
								incomplete: 0
							}
						}
					}
					else {
						return product;
					}
				})
			}
		})
	}

	processProducts() {
		let incomplete = [];
		let complete = [];
		let unavailable = [];
		let erpCodeIndex = {};
		
		for(let gnth = 0; gnth < this.state.products.length; gnth++) {

			for(let pnth = 0; pnth < this.state.products[gnth].products.length; pnth++){
				let product = this.state.products[gnth].products[pnth];

				if(product.product_erp_code !== config.deliveryProductErpCode) {
					erpCodeIndex[product.product_erp_code] = product;

					if(product.quantity.total > parseFloat((product.quantity.packed + product.quantity.collected + product.quantity.unavailable).toFixed(4))){
						incomplete.push(product);
					}
	
					if(product.quantity.collected > 0) {
						complete.push(product);
					}
	
					if(product.quantity.unavailable > 0) {
						unavailable.push(product);
					}
				}
			}
		}

		this.setState({
			incompleteProducts: incomplete,
			completeProducts: complete,
			unavailableProducts: unavailable,
			erpCodeIndex: erpCodeIndex,
		});
	}

	checkCompletion() {
		return (this.state.incompleteProducts.length === 0);
	}

	completeCollection(force) {
		if(force !== true && this.state.incompleteProducts.length) {
			openModal(
				'warning',
				{
					message: 'Listede henüz toplanmamış ürünler var. İşleme devam edemezsiniz.',
				}
			);
		}
		else {
			let vm = this;

			vm.props.onComplete({products: vm.state.products, bagData: ['Dolap']});
		}
	}

	updateProduct(prevProd, newProd) {
		let newProds = cloneDeep(this.state.products).map((group, gnth) => {
			group.products = group.products.map((product, pnth) => {
				return (isExact(product, prevProd) ? newProd : product);
			});

			return group;
		});

		this.setState({ products: newProds });

		closeModal();
	}

	openProductModal(product) {
		openModal(
			'productCollectionOpts',
			{
				mode: this.props.viewMode,
				product: product,
				onModify: (newProduct) => {
					this.updateProduct(product, newProduct);
				}
			}
		);
	}

	barcodeEntered(erpCode, quantity) {
		let vm = this;

		setTimeout(() => {
			const product = vm.state.erpCodeIndex[erpCode];
			if(product) {
				if(this.props.viewMode === 'collection') {
					if(!!product?.to_be_processed && product?.prepare_status !== 1) {
						openModal('warning', {
							message: `Ürün hazır olmadığı için toplanamıyor.`,
						});
					}
					else {
						var collectableTotal = parseFloat((product.quantity.total -  (product.quantity.collected + product.quantity.unavailable + product.quantity.packed)));

						if(product.to_be_processed && product.prepare_status === 1) {
							collectableTotal = collectableTotal * config.excessibleAmountMultiplier;
						}
	
						if(collectableTotal === 0) {
							openModal('warning', {
								message: `Bu ürünün tamamı toplanmıştır.`,
							});
						}
						else if(collectableTotal > 0 && collectableTotal < 10) {
							const increaseAmount = quantity || (collectableTotal < 1 ? collectableTotal : 1);
							this.updateProduct(product, {
								...product,
								quantity: {
									...product.quantity,
									collected: product.quantity.collected + increaseAmount,
								}
							});
						}
						else if(collectableTotal >= 10) {
							this.openProductModal(product);
						}
					}
				}
				else {
					this.openProductModal(product);
				}
			}
			else {
				openModal('warning', {
					message: `Barkod sisteminden dönen <strong>"${erpCode}"</strong> ERP kodlu ürün siparişte bulunamadı.`
				});
			}
		}, 500)
	}

	render(){
		let viewMode = this.props.viewMode;
		let products = (viewMode === 'collection' ? this.state.incompleteProducts : (viewMode === 'collected' ? this.state.completeProducts : this.state.unavailableProducts));

		return (
			<React.Fragment>
				<div className="section sale-products">
					{products.length ? 
						<div className="products-group">
							<div className="group-head">
								<div className="wrapper head-wrap">
									{viewMode === 'collection' ? 'Toplanacak Ürünler' : (viewMode === 'collected' ? 'Toplanan Ürünler' : 'Bulunamayan Ürünler')}

									{/* <Btn
										icon="barcode"
										wide
										className="text big-icon"
										onClick={() => {
											openModal('barcodeReader', { onBarcodeRead: this.barcodeEntered })
										}} /> */}
								</div>
							</div>
							<BarcodeInput
								dynamic
								autoClear
								saleID={this.state.saleID}
								// ref={(ref) => {this.barcodeReader = ref}}
								onSubmit={this.barcodeEntered} />
							<div className="group-products">
								<div className="wrapper">
								{products.map((product, nth) => {
									let leftAmount = parseFloat((product.quantity.total - (product.quantity.packed + product.quantity.collected + product.quantity.unavailable)).toFixed(config.quantityDecimals));
									return (
										<button
											disabled={!!product.to_be_processed && product.prepare_status === 0}
											type="button"
											onClick={() => { this.openProductModal(product) }}
											className="products-product" key={nth}>
											<Image
												bg
												contain
												src={getCdnImage(product.image, { width: 100, height: 100 })}
												className="product-image" />

											<div className="product-text">
												<div className="text-maincontent">
													<strong className="product-title">{product.product_name}</strong>
													<div className="product-barcode">
														<strong>Ürün Kodu: </strong>
														<span>{product.product_erp_code ? product.product_erp_code : '-'}</span>
													</div>
												</div>
												<div className="product-bottominfo">
													<div className="product-price">{formatNumber(product.product_price)} TL</div>
													<div className="product-quantity">
														<p className="quantity-num total">
															{product.quantity.total} {product.quantity_type_text}
															<span>{leftAmount > 0 ? ' (Kalan: '+leftAmount+' '+product.quantity_type_text+')' : '(Tamamlandı)'}</span>
														</p>
														{product.quantity.packed > 0 &&
															<p className="quantity-num sub packed">Paketlenen: {product.quantity.packed} {product.quantity_type_text}</p>
														}
														{product.quantity.collected > 0 &&
															<p className="quantity-num sub collected">Toplanan: {product.quantity.collected} {product.quantity_type_text}</p>
														}
														{product.quantity.unavailable > 0 &&
															<p className="quantity-num sub unavailable">Bulunamayan: {product.quantity.unavailable} {product.quantity_type_text}</p>
														}
													</div>
												</div>
												{!!product.to_be_processed &&
													<div className="text-maincontent">
														{product.prepare_status === 0 &&
															<div className="product-info error">
																Bu ürün hazırlanıyor.
															</div>
														}
														{product.prepare_status === 1 &&
															<div className="product-info success">
																Ürün hazır, lütfen toplayınız.
															</div>
														}
														{product.prepare_status === -1 &&
															<div className="product-info warning">
																Ürün eksik / bulunamadı.
															</div>
														}
													</div>
												}
											</div>
										</button>
									)
								})}
								</div>
							</div>
						</div>
						:
						<div className="wrapper">
							<div className={'products-message' + (viewMode === "collection" ? ' success' : '')}>
								{viewMode === "collection" ? 'Toplama işlemi tamamlandı. Devam etmek için "Toplamayı Bitir" butonuna basın.' : 'Ürün yok'}
							</div>
						</div>
					}
				</div>

				<div className="section sale-controls">
					<Btn
						disabled={this.props.loading || viewMode === 'collection'}
						className="controls-btn"
						// icon={viewMode === 'collection' ? 'check-full' : undefined}
						onClick={()=>{ this.props.onNavigate('collection'); }}>
						Toplanacak Ürünler
					</Btn>
					<Btn
						disabled={this.props.loading || viewMode === 'collected'}
						className="controls-btn dark"
						// icon={viewMode === 'collected' ? 'check-full' : undefined}
						onClick={()=>{ this.props.onNavigate('collected'); }}>
						Toplanan Ürünler
					</Btn>
					<Btn
						disabled={this.props.loading || viewMode === 'unavailable'}
						className="controls-btn error"
						// icon={viewMode === 'unavailable' ? 'check-full' : undefined}
						onClick={()=>{ this.props.onNavigate('unavailable'); }}>
						Bulunamayan Ürünler
					</Btn>

					<Btn
						disabled={this.props.loading}
						className="controls-btn success wide"
						onClick={this.completeCollection}>Toplamayı Bitir</Btn>
				</div>
			</React.Fragment>
		)
	}
}

Collection.defaultProps = {
	viewMode: 'collection'
} 