import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'es6-promise/auto'


import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import store from './store/'
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker';

// Styles
import './assets/scss/app.scss';

require('string.prototype.repeat');

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();