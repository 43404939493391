import React, { useState /*useCallback*/ } from 'react'
import formatNumber from 'functions/format-number'

// Partials
import Btn from 'views/partials/btn'
import { InputForm, FormInput } from 'views/partials/forms'

// Sections
//import BarcodeInput from 'views/sections/barcode-input'

// Deps
import { connect } from "react-redux"
import { closeModal } from 'utils/modals'
import saleServices from 'services/sale'
// import print from 'functions/print'

// Assets

const mapStateToProps = state => {
	return {
		terminalDevice: state.site.terminalDevice
	};
};

const ProductDeliveryCompleteModal = (props) => {
	const sale = props.sale;
	// const sale = {...props.sale, delivery_blocked_by_time: true, delivery_block_remaining_time: 8};

	// const [deliveryReceiptPrinted/*, setDeliveryReceiptPrinted*/] = useState(false);
	//const [bagReceiptPrinted, setBagReceiptPrinted] = useState(false);
	const [loading, setLoading] = useState(false);
	const [printing/*, setPrinting*/] = useState(false);

	// const printDeliveryReceipt = () => {
	// 	setPrinting(true);
	// 	saleServices.getDeliveryReceipt(sale.id).then((html) => {
	// 		print(html).then(() => {
	// 			setDeliveryReceiptPrinted(true);
	// 			setPrinting(false);
	// 		});

	// 	}).catch(() => {
	// 		setPrinting(false);
	// 	})
	// }

	// const printBagReceipt = () => {
	// 	setPrinting(true);
	// 	saleServices.getBagReceipt(sale.id).then((html) => {
	// 		print(html).then(() => {
	// 			setBagReceiptPrinted(true);
	// 			setPrinting(false);
	// 		});

	// 	}).catch(() => {
	// 		setPrinting(false);
	// 	})
	// }

	const finish = (formData) => {
		setLoading(true);

		saleServices.finishDeliverySale(sale.id, {
			...formData,
			fee_fullname: '-',
			fee_poss: '-',

		}).then(() => {
			closeModal();
			props.onComplete();
		}).catch(() => {
		}).finally(() => {
			setLoading(false);
		})
	}

	return (
		<div className={props.className}>
			<div className="modal-innercontent">
				<h2 className="opts-title">Teslimat İşlemleri</h2>

				{sale.delivery_blocked_by_time && sale.delivery_block_remaining_time &&
					<p className="opts-subtitle">Teslimatı en erken {sale.delivery_block_remaining_time} dakika içerisinde tamamlayabilirsiniz.</p>
				}

				{/* <Btn
					className="opts-opt block"
					disabled={printing || loading}
					onClick={printDeliveryReceipt}>
					Teslimat Tutanağı Yazdır
				</Btn> */}

				{/*
					<Btn
						className="opts-opt block"
						disabled={printing || loading}
						onClick={printBagReceipt}>
						Poşet Fişi Yazdır
					</Btn>
				*/}

				{sale.status === 3 &&
					<Btn
						tag="link"
						href="collectionDetail"
						params={{ id: sale.id }}
						onClick={() => {
							closeModal();
						}}
						className="opts-opt block text"
						loading={loading}
						disabled={loading || printing}>
						Yeniden Topla
					</Btn>
				}

				{(sale.sale_payment_typeid === 4) ?
					<>
						<div className="opts-total">
							<strong className="total-title">Tahsil Edilecek Tutar</strong>
							<p className="total-price">{formatNumber(sale.delivered_total, { decimals: 2 })} TL</p>
						</div>
						<InputForm className="opts-employeeform" onSubmit={finish}>
							<strong className="employeeform-title">Teslimat Bilgileri</strong>
							<FormInput
								placeholder="Teslim Alınan Tutar"
								name="fee_price"
								decimals={2}
								mask="0,,,,,,,,,,,,,,,,,"
								validation={{
									required: 'Tutarı girmelisiniz.',
									numeric: 'Geçerli bir tutar girmelisiniz.'
								}}
								type="number" />
							{/* {sale.sale_payment_typeid === 4 &&
								<FormInput
									placeholder="Ödeme Çekilen Pos"
									name="fee_poss"
									validation={{
										required: 'Pos adı girmelisiniz.'
									}}
									type="number" />
							} */}
							<Btn
								type="submit"
								className="opts-opt block success"
								loading={loading}
								disabled={sale.delivery_blocked_by_time || loading || printing}>
								Teslim Et
							</Btn>
						</InputForm>
					</>
					:
					<Btn
						className="opts-opt block success"
						loading={loading}
						onClick={() => { finish() }}
						// disabled={sale.delivery_blocked_by_time || !deliveryReceiptPrinted || loading || printing}>
						disabled={printing}>
						Teslim Et
					</Btn>
				}

				<Btn
					className="opts-opt block error"
					disabled={loading || printing}
					onClick={closeModal}>
					Kapat
				</Btn>
			</div>
		</div>
	)
}

const ConnectedModal = connect(mapStateToProps)(ProductDeliveryCompleteModal);

ConnectedModal.defaultProps = {
	className: "",
	containerClass: "modal-product-opts",
	name: "productDeliveryComplete",
	preventClose: true,
	//
	sale: false,
}

export default ConnectedModal;