// Functions
import imageLoad from 'functions/image-load'

const print = (html) => {
	/* eslint-disable no-loop-func */
	return new Promise((resolve, reject) => {
		let container = document.createElement('div');
		container.innerHTML = html.trim();
		container.setAttribute('id', 'printContainer');

		window.document.body.appendChild(container);

		let images = document.getElementsByTagName('img');
		let loaded = 0;
		for (let i = 0; i < images.length; i++) {
			imageLoad(images[i].src, () => {
				loaded++;

				if (loaded >= images.length) {
					window.document.body.classList.add('print');
					setTimeout(function () {
						window.print();

						setTimeout(function () {
							window.document.body.classList.remove('print');
							try {
								window.document.getElementById('printContainer').outerHTML = '';
							}
							catch (e) {
								console.warn('Print Error?');
							}
							resolve(true);
						}, 1000);
					}, 150);
				}
			})
		}
	});
	/* eslint-enable no-loop-func */
}

export default print;