import React, { useState, useEffect, useRef } from 'react'

// Deps
//import omit from 'lodash/omit'
//import extend from 'lodash/extend'
import { renderToString } from 'react-dom/server'
import uid from 'functions/uid'

const Select = React.forwardRef((props, ref) => {
	const [value, setValue] = useState(null);
	const [label, setLabel] = useState(props.placeholder);

	const id = useRef(props.id ? props.id : uid('select'));

	const changeEvent = (e) => {
		let newVal = props.options.find((opt) => {
			return opt.value.toString() === e.target.value.toString();
		});

		setValue(newVal ? newVal : null);

		if(props.onChange) {
			props.onChange(newVal ? newVal : null);
		}
	}

	useEffect(() => {
		setLabel(value ? value.label : props.placeholder);
	}, [value, props.placeholder]);

	useEffect(() => {
		setValue(props.value ? props.value : null);
	}, [props.value, props.options]);
	
	return (
		<div className="selectwrap">
			<label className={'selectwrap-label' + (value ? '' : ' placeholder')} htmlFor={id.current}>
				{label}
			</label>
			<select
				id={id.current}
				name={props.name}
				className="selectwrap-input"
				disabled={props.disabled}
				value={value ? value.value : ""}
				onChange={changeEvent}>
				{(props.placeholder !== false || props.allowEmpty) &&
					<option
						value=""
						ref={ref}
						disabled={!props.allowEmpty}>
						{props.placeholder}
					</option>
				}
				{props.options &&
					<React.Fragment>
						{props.options.map((option, nth) => {
							//let selected = (value ? value.value.toString() === option.value.toString() : false);
							let labelText = React.isValidElement(option.label) ? renderToString(option.label) : option.label;
							labelText = labelText.replace(/<\/?[^>]+(>|$)/g, "");

							return (
								<option
									//selected={selected}
									key={option.value ? option.value : nth}
									value={option.value}>
									{labelText}
								</option>
							)
						})}
					</React.Fragment>
				}
			</select>
		</div>
	)
});

Select.defaultProps = {
	className: "",
	placeholder: 'Seçiniz...',
	allowEmpty: false,
	value: null,
}

export default Select;