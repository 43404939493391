// Deps
import React from 'react';
import config from 'config';
import formatNumber from 'functions/format-number'
import cloneDeep from 'lodash/cloneDeep'
import isExact from 'functions/is-exact'
import getCdnImage from 'functions/get-cdn-image'
import { openModal, closeModal } from 'utils/modals'

// Partials
import Btn from 'views/partials/btn'
import Image from 'views/partials/image'
import Loader from 'views/partials/loader'

// Services
import saleServices from 'services/sale'
import { redirect } from 'controllers/navigator';


export default class ReturnDetail extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			sale: false,
			returnableProducts: [],
			returnProductCount: 0,
			barcodeIndex: {},
		}

		this.processProducts = this.processProducts.bind(this);
		this.completeCollection = this.completeCollection.bind(this);
		this.barcodeEntered = this.barcodeEntered.bind(this);
		this.openProductModal = this.openProductModal.bind(this);
		this.updateProduct = this.updateProduct.bind(this);
		this.getData = this.getData.bind(this);
	}

	getData() {
		this.setState({ sale: false, })
		saleServices.getCollectionSale(this.props.match.params.id).then((sale) => {
			this.setState({sale: sale});
		}).finally(() => {
			this.setState({loading: false});
		})
	}

	componentDidMount() {	
		this.getData();
	}

	componentDidUpdate(prevProps, prevState) {
		if(!isExact(prevState.sale?.product_groups, this.state.sale?.product_groups)){
			this.processProducts();
		}
	}

	processProducts() {
		let returnable = [];
		let returnCount = 0;
		let barcodeIndex = {};
		
		if(this.state.sale && this.state.sale.product_groups) {
			for(let gnth = 0; gnth < this.state.sale.product_groups.length; gnth++) {
	
				for(let pnth = 0; pnth < this.state.sale.product_groups[gnth].products.length; pnth++){
					let product = this.state.sale.product_groups[gnth].products[pnth];
					barcodeIndex[product.barcode] = { id: product, groupIndex: gnth, index: pnth }
	
					if(product.product_erp_code !== config.deliveryProductErpCode && product.quantity.collected - product.quantity.return > 0) {
						if(product.quantity.return_new) {
							returnCount += product.quantity.return_new;
						}
						returnable.push({
							...product,
							quantity: {
								...product.quantity,
								return_new: product.quantity.return_new ? product.quantity.return_new : 0,
							}
						});
					}
				}
			}
		}
		
		this.setState({
			returnableProducts: returnable,
			barcodeIndex: barcodeIndex,
			returnProductCount: returnCount,
		});
	}

	checkCompletion() {
		return (this.state.incompleteProducts.length === 0);
	}

	completeCollection() {
		if(this.state.returnProductCount === 0) {
			openModal(
				'warning',
				{
					message: 'İade gerçekleştirmek için en az bir ürün seçmelisiniz.',
				}
			);
		}
		else {
			openModal(
				'confirm',
				{
					message: 'İade işlemini gerçekleştirmek istediğinizden emin misiniz?',
					onConfirm: () => {
						let returnProducts = [];
			
						for(let prod of this.state.returnableProducts) {
							if(prod.quantity.return_new > 0) {
								returnProducts.push({
									id: prod.id,
									quantity: prod.quantity.return_new,
								})
							}
						}
			
						this.setState({ loading: true })
			
						saleServices.returnProducts(this.state.sale.id, returnProducts).then(() => {
							redirect('deliveryDetail', { id: this.state.sale.id });
						}).catch(() => {
							this.setState({ loading: false })
						})
					}
				}
			);
		}
	}

	updateProduct(prevProd, newProd) {
		let newProds = cloneDeep(this.state.sale.product_groups).map((group, gnth) => {
			group.products = group.products.map((product, pnth) => {
				return (prevProd.id === product.id ? newProd : product);
			});

			return group;
		});

		this.setState({ sale: { ...this.state.sale, product_groups: newProds, } });

		closeModal();
	}

	openProductModal(product) {
		openModal(
			'productReturnOpts',
			{
				mode: 'return',
				product: product,
				onModify: (newProduct) => {
					this.updateProduct(product, newProduct);
				}
			}
		);
	}

	barcodeEntered(barcode) {
		let vm = this;

		setTimeout(() => {
			if(vm.state.barcodeIndex[barcode]) {
				this.openProductModal(vm.state.barcodeIndex[barcode].id);
			}
			else {
				openModal('warning', {
					message: `<strong>"${barcode}"</strong> barkodlu ürün bulunamadı.`
				});
			}
		}, 500)
	}

	render(){
		let products = this.state.returnableProducts;

		return (
			<React.Fragment>
				<div className="section sale-products loader-container">
					<Loader inner loading={this.state.sale === false} />
					{this.state.sale &&
						<>
							{products?.length ? 
								<div className="products-group">
									<div className="group-head">
										<div className="wrapper head-wrap">
											İade Edilebilir Ürünler
										</div>
									</div>
									<div className="group-products">
										<div className="wrapper">
										{products.map((product, nth) => {
											let leftAmount = parseFloat((product.quantity.return_new).toFixed(config.quantityDecimals));
											return (
												<button
													disabled={this.state.loading}
													type="button"
													onClick={() => { this.openProductModal(product) }}
													className={`products-product${(product.quantity.return > 0 || leftAmount > 0) ? ' error' : ''}`} key={nth}>
													<Image
														bg contain
														src={getCdnImage(product.image, { width: 100, height: 100 })}
														className="product-image" />

													<div className="product-text">
														<div className="text-maincontent">
															<strong className="product-title">{product.product_name}</strong>
														</div>
														<div className="product-bottominfo">
															<div className="product-price">{formatNumber(product.product_price)} TL</div>
															<div className="product-quantity">
																<p className="quantity-num total">
																	{product.quantity.collected - product.quantity.return} {product.quantity_type_text}
																	<span>(İade Edilen: {leftAmount} {product.quantity_type_text})</span>
																</p>
																{product.quantity.return > 0 &&
																	<p className="quantity-num sub returned">Daha Önce İade Edilen: {product.quantity.return} {product.quantity_type_text}</p>
																}
															</div>
														</div>
													</div>
												</button>
											)
										})}
										</div>
									</div>
								</div>
								:
								<div className="wrapper">
									<div className={'products-message'}>
										Bu siparişte iade edilebilecek ürün bulunmuyor.
									</div>
								</div>
							}
						</>
					}
				</div>

				<div className="section sale-controls">
					<Btn
						disabled={!this.state.sale || this.state.loading}
						loading={this.state.loading}
						className="controls-btn success wide"
						onClick={this.completeCollection}>İade Gerçekleştir</Btn>
				</div>
			</React.Fragment>
		)
	}
}