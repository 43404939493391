import config from 'config'

const defaultOptions = {
	format: 'jpg',
	quality: '70',
}

export const getCdnImage = (url, paramOptions) => {
	const urlParams = (() => {
		const options = { ...defaultOptions, ...paramOptions };
		const params = [];
		for(const param of Object.keys(options)) {
			switch(param) {
				case 'width':
					params.push(`w=${options[param]}`)
				break;
				case 'height':
					params.push(`h=${options[param]}`)
				break;
				case 'format':
					params.push(`format=${options[param]}`)
				break;
				case 'quality':
					params.push(`quality=${options[param]}`)
				break;
				case 'bgColor':
					params.push(`bgcolor=${options[param]}`)
				break;
				default:
				break;
			}
		}

		if(params.length) {
			return '?'+params.join('&');
		}
		else {
			return '';
		}
	})()

	return `${config.cdnURL}${url}${urlParams}`;
}

export default getCdnImage;