import React from 'react'
import config from 'config'

// Partials
import { FormInput } from 'views/partials/forms'
import Btn from 'views/partials/btn'

// Deps
import { connect } from "react-redux"
import { closeModal } from 'utils/modals'
import cloneDeep from 'lodash/cloneDeep'
import isExact from 'functions/is-exact'

// Assets

const mapStateToProps = state => {
	return {
		terminalDevice: state.site.terminalDevice
	};
};

class ProductReturnOptsModal extends React.Component {
	
	constructor(props) {
		super(props);

		this.state = {
			amount: (props.mode === 'return' ? (props.product.quantity.return_new ? props.product.quantity.return_new : 0) : (props.product.quantity.cancelReturn_new ? props.product.quantity.cancelReturn_new : 0)),
			availableAmount: this.calculateAvailable(),
		}

		this.returnAction = this.returnAction.bind(this);
		this.calculateAvailable = this.calculateAvailable.bind(this);
	}

	componentDidUpdate(prevProps) {
		if(!isExact(prevProps.product, this.props.product) || prevProps.mode !== this.props.mode) {
			this.setState({ availableAmount: this.calculateAvailable() })
		}
	}

	optionClick(opt) {
		if(opt.onClick){
			opt.onClick();
			if(opt.closeOnClick){
				closeModal();
			}
		}
		else {
			closeModal();
		}
	}

	calculateAvailable() {
		const product = this.props.product;

		return (this.props.mode === 'return' ? 
			(product.quantity.collected - product.quantity.return)
			:
			product.quantity.return
		);
	}

	returnAction() {
		if(this.props.onModify) {

			let product = cloneDeep(this.props.product);
			let available = this.state.availableAmount;
			

			let increment = parseFloat(parseFloat(this.state.amount).toFixed(config.quantityDecimals));

			if(increment <= available) {

				switch(this.props.mode) {
					case 'return':
						product.quantity.return_new = increment;
					break;
					default:
						product.quantity.cancelReturn_new = increment;
					break;
				}

				this.props.onModify(product);
			}
		}
	}

	render() {
		let mode = this.props.mode;
		let product = this.props.product;
		let availableAmount = this.state.availableAmount;

		return (
			<div className={this.props.className}>
				{this.props.closeBtn}
				<div className="modal-innercontent">
					<div className="quantity-product">
						<strong className="product-title">{product.product_name}</strong>
						<p className="product-quantity">
							<strong>{mode === 'return' ? 'İade Edilebilecek' : 'İptal Edilebilecek'}:</strong> <span>{availableAmount}</span>
						</p>
					</div>
					<div className="quantity-controls">
						<FormInput
							className="controls-counter"
							type="counter"
							value={this.state.amount}
							onChange={(e) => { this.setState({ amount: e }); }}
							decimals={product.quantity_type === 2}
							max={availableAmount} />

						{mode === 'return' ? 
							<Btn className="controls-button success x2" onClick={() => { this.returnAction(); }}>İadeyi Uygula</Btn>
							:
							<Btn className="controls-button error x2" onClick={() => { this.returnAction(); }}>İade İptali Uygula</Btn>
						}
					</div>
				</div>
			</div>
		)
	}
}

const ConnectedModal = connect(mapStateToProps)(ProductReturnOptsModal);

ConnectedModal.defaultProps = {
	className: "",
	containerClass: "modal-product-quantity",
	name: "productReturnOpts",
	product: false,
	mode: 'return',
}

export default ConnectedModal;