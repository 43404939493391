import React, { useState, useEffect } from 'react'

// Deps
// import { redirect } from 'controllers/navigator'
import formatDate from 'functions/format-date'

// Partials
// import { InputForm, FormInput } from 'views/partials/forms'
import Btn from 'views/partials/btn'
import Loader from 'views/partials/loader'

// Sections
// import BarcodeInput from 'views/sections/barcode-input'

// Services
import saleServices from 'services/sale'

const DeliveryList = (props) => {
	const query = props.match.params.query; 

	// const [searchText, setSearchText] = useState(query ? query : '');
	const [sales, setSales] = useState(null);

	// const submit = (formData) => {
	// 	redirect('deliverySearch', searchText ? {query: searchText} : {});
	// }

	// const reset = (formData) => {
	// 	setSearchText('');
	// 	redirect('deliverySearch');
	// }

	useEffect(() => {
		saleServices.findReturnSale(query ? query : null).then((payload) => {
			setSales(payload.sales);
		}).catch(() => {
			setSales(false);
		});
	}, [query])

	return (
		<>
			<div className="section sale-search-results loader-container">
				<div className="wrapper">
					<Loader inner loading={sales === null} />
					{(sales !== null && sales !== false && sales.length > 0) ?
						<ul className="listing">
							{sales.map((sale, nth) => (
								<li className="listing-item" key={sale.id}>
									<div className="item-field">
										<strong>Satış Kodu</strong>
										<span>{sale.sale_code}</span>
									</div>
									<div className="item-field">
										<strong>Müşteri Adı</strong>
										<span>{sale.sale_client}</span>
									</div>
									<div className="item-field">
										<strong>Durum</strong>
										<span>{sale.status_text}</span>
									</div>
									<div className="item-field">
										<strong>Ödeme Yöntemi</strong>
										<span>{sale.sale_payment_name}</span>
									</div>
									<div className="item-field">
										<strong>Tutar</strong>
										<span>{sale.total} TL</span>
									</div>
									<div className="item-field">
										<strong>Teslimat Saati</strong>
										<span className="date">
											<span>{formatDate(sale.delivery_calendar_date, 'DD MMMM YYYY')} - {sale.delivery_calendar_hour}</span>
										</span>
									</div>
									<div className="item-controls">
										<Btn
											tag="link"
											params={{id: sale.id}}
											href="returnDetail"
											low>
											Siparişi Gör
										</Btn>
									</div>
								</li>
							))}
						</ul>
						:
						<React.Fragment>
							{sales !== null &&
								<div className="listing-message">
									İade bekleyen sipariş yok.
								</div>
							}
						</React.Fragment>
					}
				</div>
			</div>
		</>
	)
}

export default DeliveryList