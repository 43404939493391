import moment from 'moment'
import 'moment/locale/tr';

const formatDate = (date, opts = {}) => {
	let defaultOpts = {
		format: 'DD MMMM YYYY, HH:mm'
	};

	if(typeof opts === 'string'){
		opts = {...defaultOpts, format: opts};
	}
	else {
		opts = {...defaultOpts, ...opts};
	}

	return moment(date).format(opts.format);
}

export default formatDate