const initialState = {
  messages: [],
};

function messengerReducer(state = initialState, action) {
	if (action.type === "ADD_MESSAGE") {
		return Object.assign({}, state, {
			messages: [...state.messages, action.payload]
		});
	}
	else if (action.type === "CLEAR_MESSAGES") {
		return Object.assign({}, state, {
			messages: []
		});
	}
	return state;
};
export default messengerReducer;