module.exports = {
	pages: {
		login: {
			path: "/login",
			component: "Login",
			exact: true,
			linkTitle: "Giriş",
			title: "Giriş Yapın",
			description: "Mağaza Operasyon Ekranı",
			headTitle: "Metro Operasyon Giriş Ekranı",
			loginRedirect: '/',
		},
		dashboard: {
			path: "/",
			component: "Dashboard",
			exact: true,
			linkTitle: "Panel",
			title: false,
			requiresLogin: true,
		},
		keyListener: {
			path: "/key-listener",
			component: "KeyListener",
			exact: true,
			linkTitle: "Terminal Cihazı Tuş Yakalama",
			title: 'Terminal Cihazı Tuş Yakalama',
			headTitle: "Terminal Cihazı Tuş Yakalama",
			requiresLogin: true,
		},
		collectionList: {
			path: "/collection-list",
			component: "CollectionList",
			exact: true,
			linkTitle: "Toplama İşlemleri",
			title: "Toplama İşlemleri",
			headTitle: "Toplama İşlemleri",
			requiresLogin: true,
			prevRoute: "dashboard",
		},
		collectionDetail: {
			path: "/collection-list/detail/:id",
			component: "CollectionDetail",
			exact: true,
			linkTitle: "Sipariş İşlemleri",
			title: "Sipariş İşlemleri",
			headTitle: "Sipariş İşlemleri",
			requiresLogin: true,
			plainHeader: true,
			hideHeader: true,
			// preventBack: true,
		},
		planningList: {
			path: "/planning-list",
			component: "PlanningList",
			exact: true,
			linkTitle: "Planlama İşlemleri",
			title: "Planlama İşlemleri",
			headTitle: "Planlama İşlemleri",
			requiresLogin: true,
			prevRoute: "dashboard",
		},
		planningDetail: {
			path: "/planning-list/detail/:id",
			component: "PlanningDetail",
			exact: true,
			linkTitle: "Planlama İşlemleri",
			title: "Planlama İşlemleri",
			headTitle: "Planlama İşlemleri",
			requiresLogin: true,
			plainHeader: true,
			// hideHeader: true,
			// preventBack: true,
		},
		shipmentList: {
			path: "/shipment-list",
			component: "ShipmentList",
			exact: true,
			linkTitle: "Sevkiyat İşlemleri",
			title: "Sevkiyat İşlemleri",
			headTitle: "Sevkiyat İşlemleri",
			requiresLogin: true,
			plainHeader: false,
			hideHeader: false,
			prevRoute: "dashboard",
		},
		loadList: {
			path: "/load-list",
			component: "LoadList",
			exact: true,
			linkTitle: "Yükleme Kontrolü İşlemleri",
			title: "Yükleme Kontrolü İşlemleri",
			headTitle: "Yükleme Kontrolü İşlemleri",
			requiresLogin: true,
			plainHeader: false,
			hideHeader: false,
			prevRoute: "dashboard",
		},
		deliveryList: {
			path: "/delivery-list/",
			component: "DeliveryList",
			exact: true,
			linkTitle: "Teslimat İşlemleri",
			title: "Teslimat İşlemleri",
			headTitle: "Teslimat İşlemleri",
			requiresLogin: true,
			plainHeader: false,
			hideHeader: false,
			prevRoute: "dashboard",
		},
		deliveryDetail: {
			path: "/delivery-list/detail/:id",
			component: "DeliveryDetail",
			exact: true,
			linkTitle: "Teslimat Detayı",
			title: "Teslimat Detayı",
			headTitle: "Teslimat Detayı",
			requiresLogin: true,
			plainHeader: true,
			hideHeader: false,
			prevRoute: "deliveryList",
		},
		deliveryDetailReturns: {
			path: "/delivery-list/detail/:id/returns",
			component: "DeliveryDetailReturns",
			exact: true,
			linkTitle: "Teslimat İade İşlemleri",
			title: "Teslimat İade İşlemleri",
			headTitle: "Teslimat İade İşlemleri",
			requiresLogin: true,
			plainHeader: true,
			hideHeader: false,
			prevRoute: "deliveryList",
			prevRouteFallback: true,
		},
		returnList: {
			path: "/return-list/",
			component: "ReturnList",
			exact: true,
			linkTitle: "İade İşlemleri",
			title: "İade İşlemleri",
			headTitle: "İade İşlemleri",
			requiresLogin: true,
			plainHeader: false,
			hideHeader: false,
			prevRoute: "dashboard",
		},
		returnDetail: {
			path: "/return-list/detail/:id",
			component: "ReturnDetail",
			exact: true,
			linkTitle: "Sipariş İade İşlemleri",
			title: "Sipariş İade İşlemleri",
			headTitle: "Sipariş İade İşlemleri",
			requiresLogin: true,
			plainHeader: true,
			hideHeader: false,
			prevRoute: "returnList",
			prevRouteFallback: true,
		},
		notfound: {
			path: false,
			component: "NotFound",
			exact: false,
			linkTitle: "404",
			title: "Sayfa Bulunamadı",
			requiresLogin: false,
			status: 404,
		},
	}
}