const initialState = {
	modalData: false,
};

const modalReducer = (state = initialState, action) => {
	if (action.type === "OPEN_MODAL") {
		return Object.assign({}, state, {
			modalData: action.payload
		});
	}
	else if (action.type === "CLOSE_MODAL") {
		return Object.assign({}, state, {
			modalData: false
		});
	}
	return state;
};

export default modalReducer;