const formatNumber = (num, opts = {}) => {
	let options = {
		showDecimals: true,
		decimals: 2,
		...opts
	};
	let plainNum = Math.trunc(num);
	let result = plainNum.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

	if (options.showDecimals) {
		result += (num - plainNum).toFixed(options.decimals).toString().replace('.', ',').replace('0,', ',');
	}

	return result;
}

export default formatNumber