import React from 'react';

// Partials
import Btn from 'views/partials/btn'

// Deps
import { InputForm, FormInput } from 'views/partials/forms'
// import serializeObject from 'functions/serialize-object'
import userServices from 'services/user'

// Controllers
// ...

class Login extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			submitting: false,
		};

		this.submitLogin = this.submitLogin.bind(this);
		this._mounted = false;
	}

	componentDidMount() {
		this._mounted = true;
	}

	componentWillUnmount() {
		this._mounted = false;
	}

	submitLogin(formData) {
		this.setState({
			submitting: true,
		});

		userServices.login(formData.username, formData.password).catch(() => {
			
		}).finally(() => {
			if(this._mounted) {
				this.setState({
					submitting: false,
				})
			}
		})
	}

	render() {
		return (
			<div className="section login">
				<div className="login-wrap wrapper">
					<InputForm
						className="login-form"
						onSubmit={this.submitLogin}>
						<FormInput
							type="text"
							icon="user"
							placeholder="Kullanıcı Adı"
							validation="Kullanıcı adınızı giriniz."
							name="username" />

						<FormInput
							type="password"
							icon="pass"
							placeholder="Şifre"
							validation="Şifrenizi giriniz."
							name="password" />

						<Btn
							type="submit"
							disabled={this.state.submitting}
							loading={this.state.submitting}
							className="btn success block">
							Giriş Yap
						</Btn>
					</InputForm>
				</div>
			</div>
		);
	}
}

export default Login;