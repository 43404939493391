import React, { useEffect, useState, useMemo } from 'react'

// Partials
import Btn from 'views/partials/btn'
import { FormInput, InputForm } from 'views/partials/forms'
import Loader from 'views/partials/loader'

// Deps
import { connect } from "react-redux"
import { closeModal } from 'utils/modals'

// Services
import saleServices from 'services/sale'

const mapStateToProps = state => {
	return {
		terminalDevice: state.site.terminalDevice
	};
};

const LoadingCompleteModal = ({ sale: propSale, className, onComplete, closeBtn }) => {
	const [submitting, setSubmitting] = useState(false);
	const [sale, setSale] = useState(false);

	useEffect(() => {
		setSale(null);
		saleServices.getLoadingSale(propSale.id).then((sale) => {
			if(sale.status !== propSale.status) {
				closeModal();
			}
			else {
				setSale(sale);
			}
		}).catch(() => {
			setSale(false);
		});
	}, [propSale])

	const availableFields = useMemo(() => {
		if(sale) {
			let availableFields = [
				{
					title: 'Araç Sıcaklık',
					key: 'car',
				},
				{
					title: 'Et Sıcaklık',
					key: 'meat',
				},
				{
					title: 'Kıyma Et Sıcaklık',
					key: 'mincedMeat'
				},
				{
					title: 'Meyve/Sebze Sıcaklık',
					key: 'grocery',
				},
				{
					title: 'Şarküteri Sıcaklık',
					key: 'deli',
				},
				{
					title: 'Balık Sıcaklık',
					key: 'fish',
				},
				{
					title: 'Donuk Ürün Sıcaklık',
					key: 'frozen',
				},
			];
	
			// for(const group of sale.product_groups) {
			// }

			return availableFields;
		}
		else {
			return false;
		}

	}, [sale])

	const submit = (formFields) => {
		setSubmitting(true);

		saleServices.submitLoadingSale(sale.id, formFields).then(() => {
			onComplete();
			closeModal();
		}).catch((e) => {
			setSubmitting(false);
		})
	}

	return (
		<div className={className}>
			{closeBtn}
			<InputForm className="modal-innercontent" onSubmit={submit}>
				<h2 className="opts-title">Yükleme Öncesi Kontroller</h2>

				<div className="loader-container">
					<Loader inner loading={!availableFields} />
					{availableFields &&
						<>
							{availableFields.map((field) => (
								<div className="complete-row" key={field.key}>
									<strong>{field.title} (˚C)</strong>
									<FormInput
										className="controls-counter"
										name={field.key}
										type="counter"
										min={-100}
										max={100} />
								</div>
							))}
						</>
					}

					<div className="complete-row">
						<strong>Ürün Raf Ömrü</strong>
						<FormInput
							className=""
							id="check-shelflife"
							name="check-shelflife"
							checked={true}
							value="1"
							type="checkbox">
						</FormInput>
					</div>
					<div className="complete-row">
						<strong>Diğer Kalite Kriterleri</strong>
						<FormInput
							className=""
							id="check-quality"
							name="check-quality"
							checked={true}
							value="1"
							type="checkbox">
						</FormInput>
					</div>
					<div className="complete-row">
						<strong>Araç ve Ürünler Yükleme Öncesi</strong>
						<FormInput
							className=""
							id="check-load"
							name="check-load"
							checked={true}
							value="1"
							type="checkbox">
						</FormInput>
					</div>
					<div className="complete-row">
						<strong>Gıda Savunması Açısından</strong>
						<FormInput
							className=""
							id="check-food"
							name="check-food"
							checked={true}
							value="1"
							type="checkbox">
						</FormInput>
					</div>

					<Btn
						className="complete-cta block success"
						type="submit"
						disabled={submitting}>
						Kaydet
					</Btn>
					<Btn
						className="complete-cta block error"
						disabled={submitting}
						onClick={closeModal}>
						Kapat
					</Btn>
				</div>

			</InputForm>
		</div>
	)
}

const ConnectedModal = connect(mapStateToProps)(LoadingCompleteModal);

ConnectedModal.defaultProps = {
	className: "",
	containerClass: "modal-sale-loading-complete",
	name: "loadingComplete",
	// preventClose: false,
	//
	sale: false,
}

export default ConnectedModal;