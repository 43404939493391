import React from 'react';

// Sections
import Header from 'views/sections/header'
import SaleInfo from 'views/sections/sale-info'

// Partials
//import Btn from 'views/partials/btn'
import Loader from 'views/partials/loader'

// Deps
import { connect } from 'react-redux'
import clone from 'lodash/clone'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep';
import { openModal } from 'utils/modals'
import { redirect } from 'controllers/navigator'

// Views
import SaleList from './list'
import SaleCollection from './collection'
//import SaleCollected from './collected'

// Services
import saleServices from 'services/sale'

const mapStateToProps = state => {
	return {
		userData: state.user.data,
	};
};

class Sale extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			sale: false,
			displayMode: 'list',
		}

		this.getSale = this.getSale.bind(this);
		this.checkCache = this.checkCache.bind(this);
		this.updateProducts = this.updateProducts.bind(this);
		this.goBack = this.goBack.bind(this);
		this.navigate = this.navigate.bind(this);
		this.refetchProductStatuses = this.refetchProductStatuses.bind(this);
		this.backEvent = debounce(this.backEvent.bind(this), 200);
		this.fromCache = false;
		this.redirectCache = false;

		this.saleListComponent = false;
		this.saleCollectionComponent = false;

		this.refreshTimer = false;
	}

	componentDidMount() {
		this.getSale();

		window.addEventListener('goBack', this.backEvent, false);
	}

	componentDidUpdate(prevProps, prevState) {
		let vm = this;

		if(prevProps.match.params.id !== vm.props.match.params.id) {
			vm.checkCache().then(() => {
				vm.getSale();
			}).catch(() => {})
		}

		if(!isEqual(prevState.sale, vm.state.sale)) {
			if(vm.state.sale.user_id > 0 && vm.state.sale.user_id !== vm.props.userData.id) {
				localStorage.removeItem('terminal-collection-cache');
				if(vm.refreshTimer) {
					clearInterval(vm.refreshTimer);
				}

				openModal(
					'warning',
					{
						message: 'Bu sipariş başka bir kullanıcı tarafından toplanıyor.',
						text: 'Vazgeç ve Geri Dön',
						preventClose: true,
						onConfirm: () => {
							redirect('collectionList');
						},
					}
				)
			}
			else {
				if(vm.state.displayMode === 'list') {
					this.checkCache().then(() => {
					});
				}
				// vm.refreshTimer = setInterval(() => { this.refetchProductStatuses(); }, 10000);
			}
		}

		if(vm.state.sale && prevState.displayMode === 'list' && vm.state.displayMode === 'collection') {
			saleServices.startCollection(vm.state.sale.id, !vm.fromCache).then(() => {
			}).catch(() => {
			})
		}
	}

	componentWillUnmount() {
		if(this.refreshTimer) { clearInterval(this.refreshTimer); }
		window.removeEventListener('goBack', this.backEvent, false);
	}

	backEvent() {
		this.goBack(true);
	}

	getSale() {
		this.setState({
			loading: true,
		});

		saleServices.getCollectionSale(this.props.match.params.id).then((sale) => {
			this.setState({sale: sale});
		}).catch((e) => {
			console.warn('f', e);
		}).finally(() => {
			this.setState({loading: false});
		})
	}

	refetchProductStatuses() {
		saleServices.getCollectionSale(this.props.match.params.id).then((newSale) => {
			const curSale = cloneDeep(this.state.sale);

			if(newSale.user_id > 0 && newSale.user_id !== this.props.userData.id) {
				if(this.refreshTimer) {
					clearTimeout(this.refreshTimer);
				}
				// openModal(
				// 	'confirm',
				// 	{
				// 		message: 'UYARI: Bu sipariş bir başka kullanıcı tarafından toplanmaya başlandı.',
				// 		yesString: 'Toplamaya devam et',
				// 		noString: 'Vazgeç ve Geri Dön',
				// 		onConfirm: () => {
				// 			saleServices.startCollection(this.state.sale.id, false).then(() => {
				// 				this.refreshTimer = setInterval(() => { this.refetchProductStatuses(); }, 10000);
				// 			});
				// 		},
				// 		onDeny: () => {
				// 			localStorage.removeItem('terminal-collection-cache')
				// 			redirect('collectionList');
				// 		},
				// 	}
				// )

				openModal(
					'warning',
					{
						message: 'UYARI: Bu sipariş bir başka kullanıcı tarafından toplanmaya başlandı.',
						text: 'Vazgeç ve Geri Dön',
						preventClose: true,
						onConfirm: () => {
							redirect('collectionList');
						},
					}
				)
			}

			for(let groupNth = 0; groupNth < curSale.product_groups.length; groupNth++) {
				for(let prodNth = 0; prodNth < curSale.product_groups[groupNth].products.length; prodNth++) {
					const oldProduct = curSale.product_groups[groupNth].products[prodNth];
					const newProduct = newSale.product_groups[groupNth].products[prodNth];

					if(newProduct.product_serialid === oldProduct.product_serialid) {
						oldProduct.prepare_status = newProduct.prepare_status;
					}
				}
			}

			this.setState({ sale: curSale });
		});
	}

	checkCache() {
		let vm = this;
		return new Promise((resolve, reject) => {
			if(localStorage.getItem('terminal-collection-cache') !== null) {
				try {
					let cache = JSON.parse(localStorage.getItem('terminal-collection-cache'));
					
					if(cache.id.toString() === vm.props.match.params.id) {
						if(vm.redirectCache) {
							vm.fromCache = true;
							vm.redirectCache = false;
							vm.setState({ displayMode: (cache.type === 'collection' ? 'collection' : (cache.type === 'unavailable' ? 'unavailable' : 'collected')) });
							resolve();
						}
						else {
							openModal(
								'confirm',
								{
									message: 'Daha önce bu siparişi toplamayı yarıda bıraktınız. Toplama işlemine kaldığınız yerden devam etmek istiyor musunuz?',
									onConfirm: () => {
										vm.fromCache = true;
										vm.setState({ displayMode: (cache.type === 'collection' ? 'collection' : (cache.type === 'unavailable' ? 'unavailable' : 'collected')) });
										resolve();
									},
									onDeny: () => {
										localStorage.removeItem('terminal-collection-cache');
										resolve();
									},
								}
							)
						}
					}
					else {
						openModal(
							'confirm',
							{
								message: 'Yarım kalmış başka bir toplama işleminiz var. Yarıda kalan diğer siparişi toplamaya devam etmek istiyor musunuz?',
								onConfirm: () => {
									vm.redirectCache = true;
									redirect('collectionDetail', { id: cache.id });
									reject();
								},
								onDeny: () => {
									localStorage.removeItem('terminal-collection-cache');
									resolve();
								},
							}
						)
					}
		
				}
				catch(e) {
					localStorage.removeItem('terminal-collection-cache');
					resolve();
				}
			}
			else {
				resolve();
			}
		})

	}

	updateProducts(data) {
		let vm = this;

		vm.setState({ loading: true });
		let newSale = clone(vm.state.sale);

		newSale.product_groups = clone(data.products);
		
		let newProductData = {"collected": [], "unavailable": []};

		for(const group of newSale.product_groups){
			for(const product of group.products){
				newProductData.collected.push({
					id: product.id,
					quantity: product.quantity.collected,
				})

				newProductData.unavailable.push({
					id: product.id,
					quantity: product.quantity.unavailable,
				})
			};
		}

		saleServices.updateCollectionSale(vm.state.sale.id, newProductData, data.bagData).then((payload) => {
			localStorage.removeItem('terminal-collection-cache');
			setTimeout(function() {
				openModal(
					'productCollectionComplete',
					{
						saleID: vm.state.sale.id,
						onComplete: () => {
							redirect('collectionList');
						}
					}
				)
			}, 500);
		}).finally(() => {
			vm.setState({loading: false})
		}).catch(() => {
			setTimeout(function() {
				openModal(
					'text',
					{
						title: 'Hata',
						content: 'Satış güncellenirken bir hata ile karşılaşıldı.'
					}
				)
			}, 500);
		});
	}

	goBack(history = false) {
		let vm = this;

		switch(vm.state.displayMode) {
			case 'list':
				redirect('collectionList');
			break;
			case 'collection':
				if(localStorage.getItem('terminal-collection-cache') !== null){
					if(vm.saleCollectionComponent.checkCompletion()){
						openModal(
							'confirm',
							{
								message: 'Toplama işleminizi tamamlamak istiyor musunuz?',
								yesString: 'Evet, tamamla',
								noString: 'Hayır, iptal et ve geri git',
								onConfirm: () => {
									vm.saleCollectionComponent.completeCollection();
								},
								onDeny: () => {
									localStorage.removeItem('terminal-collection-cache');
									vm.getSale();
									vm.setState({ displayMode: 'list' })
								},
							}
						)
					}
					else {
						openModal(
							'options',
							{
								message: 'Toplama işleminiz yarıda kaldı, iptal edip geri dönmek istediğinizden emin misiniz?',
								opts: [
									// {
									// 	text: 'Kaydet ve Çık',
									// 	className: 'primary',
									// 	onClick: () => {
									// 		vm.saleCollectionComponent.completeCollection(true);
									// 	}
									// },
									{
										text: 'Vazgeç',
										className: 'dark',
										onClick: () => {}
									},
									{
										text: 'Siparişi Sıfırla, Çık',
										className: 'error',
										onClick: () => {
											localStorage.removeItem('terminal-collection-cache');
											saleServices.cancelCollection(vm.state.sale.id).then(() => {
												redirect('collectionList');
											})
											// vm.getSale();
											// vm.setState({ displayMode: 'list' })
										}
									}
								],
							}
						)
					}
				}
				else {
					saleServices.cancelCollection(vm.state.sale.id).then(() => {
						vm.setState({ displayMode: 'list' })
					})
				}
			break;
			default:
				vm.setState({ displayMode: 'list' })
			break;
		}
	}

	navigate(view) {
		if(this.state.displayMode === 'list' && view === 'collection'){
			if(this.state.sale) {
				// this.setState({ loading: true });
				// saleServices.assignCollectionSale(this.state.sale.id).then((payload) => {
				// 	this.setState({ displayMode: view })
				// }).catch(() => {
				// }).finally(() => {
				// 	this.setState({ loading: false });
				// });
				this.setState({ displayMode: view })
			}
			else {
				console.warn('Hata, satış henüz yüklenmemiş...');
			}
		}
		else {
			this.setState({ displayMode: view })
		}
	}

	render(){
		let sale = this.state.sale;
		let mode = this.state.displayMode;
		let headTitle = 'Sipariş İşlemleri';

		switch(mode) {
			case "collection": headTitle = "Toplama İşlemleri"; break;
			case "collected": headTitle = "Toplanan Ürünler"; break;
			case "unavailable": headTitle = "Bulunamayan Ürünler"; break;
			default: break;
		}

		return (
			<React.Fragment>
				<Header
					data={{
						headTitle: headTitle,
					}}
					onBackClick={this.goBack}
					/>
				<div className="page sale loader-container">
					<Loader loading={this.state.loading} />
					{sale  &&
						<React.Fragment>
							<SaleInfo sale={this.state.sale} />
							{mode === 'list' &&
								<SaleList
									ref={(ref) => { this.saleListComponent = ref; }}
									loading={this.state.loading}
									sale={this.state.sale}
									onNavigate={this.navigate}
								/>
							}
							{(mode === 'collection' || mode === 'collected' || mode === 'unavailable') &&
								<SaleCollection
									ref={(ref) => { this.saleCollectionComponent = ref; }}
									loading={this.state.loading}
									sale={this.state.sale}
									onComplete={this.updateProducts}
									onNavigate={this.navigate}
									viewMode={mode}
								/>
							}
						</React.Fragment>
					}
				</div>
			</React.Fragment>
		);
	}
}

export default connect(mapStateToProps)(Sale);