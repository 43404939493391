export const setLoading = payload => ({
	type: "SET_LOADING", payload
});

export const setMobile = payload => ({
	type: "SET_MOBILE", payload
});

export const setWw = payload => ({
	type: "SET_WW", payload
});

export const setWh = payload => ({
	type: "SET_WH", payload
});

export const setScrollPos = payload => ({
	type: "SET_SCROLLPOS", payload
});

export const setPage = payload => ({
	type: "SET_PAGE", payload
});

export const setPageNotFound = payload => ({
	type: "SET_PAGE_NOT_FOUND", payload
});

export const addMessage = payload => ({
	type: "ADD_MESSAGE", payload
});

export const clearMessages = payload => ({
	type: "CLEAR_MESSAGES", payload
});