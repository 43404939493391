const serializeObject = (form, opts = {}, seperator = ',') => {
	var defaultOpts = {
		ignoreEmpty: false,
		multipleString: false,
		multipleStringSeperator: ',',
		parseBool: true,
	}

	opts = {...defaultOpts, ...opts};

	var serialized = {};
	let elements = (form.elements ? form.elements : form.querySelectorAll('input, textarea, select'));
	for (var i = 0; i < elements.length; i++) {

		var field = elements[i];

		if (!field.name || field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') continue;

		if (field.type === 'select-multiple') {
			for (var n = 0; n < field.options.length; n++) {
				if (!field.options[n].selected) continue;
				let val = field.options[n].value;
				if(opts.parseBool && (val === 'true' || val === 'false')){
					val = (val === 'true');
				}

				if (!(opts.ignoreEmpty && val === '')) {
					let name = field.name.replace('[]', '');
					if (serialized[name] && name + '[]' === field.name) {
						if(opts.multipleString) {
							val = serialized[name] += opts.multipleStringSeperator + val;
						}
						else {
							let nval = serialized[name];
							nval.push(val);
							val = nval;
						}
					}
					serialized[name] = val;
				}
			}
		}
		else if (((field.type !== 'checkbox' && field.type !== 'radio') && opts.parseBool) || field.checked) {
			let val = field.value;

			if((field.type === 'checkbox' || field.type === 'radio') && !field.checked && opts.parseBool){
				val = false;
			}
			else if(opts.parseBool && (val === 'true' || val === 'false')){
				val = (val === 'true');
			}

			if (!(opts.ignoreEmpty && val === '')) {
				let name = field.name.replace('[]', '');
				if(name + '[]' === field.name){
					if (serialized[name]) {
						if(opts.multipleString) {
							val = serialized[name] += opts.multipleStringSeperator + val;
						}
						else {
							let nval = serialized[name]
							if(!Array.isArray(nval)){
								nval = [nval];
							}
							nval.push(val);
							val = nval;
						}
					}
					else { val = [val] }
				}
				serialized[name] = val;
			}
		}
	}

	return serialized;
}

export default serializeObject