import React from 'react';

// Functions
import formatDate from 'functions/format-date'

const SaleInfo = ({ sale }) => {
	return (
		<>
			<div className={'section sale-info' + (sale.fix_status === 2 ? ' delivery' : '')}>
				<div className="wrapper info-wrap">
					<div className="info-section wide">
						<strong>Müşteri Adı</strong>
						<span>{sale.sale_client}</span>
					</div>
					<div className="info-section wide">
						<strong>Sipariş Numarası</strong>
						<span>{sale.sale_code}</span>
					</div>
					<div className="info-section wide">
						<strong>Teslimat Tarihi</strong>
						<span>{formatDate(sale.delivery_calendar_date, 'DD MMMM YYYY')}</span>
					</div>
					<div className="info-section wide">
						<strong>Teslimat Saati</strong>
						<span>{sale.delivery_calendar_hour}</span>
					</div>
					<div className="info-section wide">
						<strong>Ödeme Yöntemi</strong>
						<span>{sale.sale_payment_name}</span>
					</div>
				</div>
			</div>
			{(sale.sale_note && sale.sale_note.length > 0) &&
				<div className="section sale-note">
					<div className="wrapper">
						<div className="note-content">
							<strong className="note-title">Müşteri Notu:</strong>
							<div>{sale.sale_note}</div>
						</div>
					</div>
				</div>
			}
		</>
	)
}

export default SaleInfo;