import extend from 'lodash/extend'
import store from "store"
import { setUserData, setToken } from 'store/user/actions'
import request from 'utils/request'
import { redirect } from 'controllers/navigator'

const userServices = {
	checkLoginStatus: () => {
		return new Promise((resolve, reject) => {
			if (localStorage["appState"]) {
				let appState = JSON.parse(localStorage["appState"]);
				if (appState.user && appState.user !== false) {
					updateUserData(appState.user, appState);

					request.get('user', false, function (payload, status, request) {
						if (payload && payload.user && payload.user !== false) {
							updateUserData(payload.user, appState);
							
							setTimeout(function() {
								resolve(payload.user);
							}, 200);
						}
						else {
							userServices.logout(true);
							reject(request);
						}
					});
				}
				else {
					reject(request);
				}
			}
			else {
				reject(false);
			}
		});
	},
	login: (username, password) => {
		return new Promise((resolve, reject) => {
			request.post('auth/login', {username, password}, function (payload, status, request) {
				if (payload && payload.user) {
					updateUserData(payload.user);

					setTimeout(function() {
						resolve(payload);
					}, 200);
				}
				else {
					userServices.logout(true);
					reject(request);
				}
			})
		});
	},
	logout: (force = false) => {
		localStorage["appState"] = JSON.stringify({ user: false, authToken: false });

		store.dispatch(setUserData(false));
		store.dispatch(setToken(false));

		if (force !== true) {
			redirect('home');
		}
	},
	// Performance
	getPerformanceData: () => {
		return new Promise((resolve, reject) => {
			request.get('user/performance', false, function (payload, status, request) {
				if (payload) {
					resolve(payload);
				}
				else {
					reject(request);
				}
			}, { dummy: false });
		});
	},
	// Receipts
	getReceipts: () => {
		return new Promise((resolve, reject) => {
			request.get('user/receipts', false, function (payload, status, request) {
				if (payload) {
					resolve(payload.sales);
				}
				else {
					reject(request);
				}
			}, { dummy: false });
		});
	},
	// End Day
	getEndDay: () => {
		return new Promise((resolve, reject) => {
			request.get('user/end-day', false, function (payload, status, request) {
				if (payload) {
					resolve(payload.sales);
				}
				else {
					reject(request);
				}
			}, { dummy: false });
		});
	},
	// Feedback
	getFeedbackData: () => {
		return new Promise((resolve, reject) => {
			request.get('customer-service/category-subject', false, function (payload, status, request) {
				if (payload && payload.list) {
					resolve(payload.list);
				}
				else {
					reject(request);
				}
			}, { dummy: false });
		});
	},
	sendFeedbackForm: (formData) => {
		return new Promise((resolve, reject) => {
			request.post('customer-service/send-form', formData, function (payload, status, request) {
				if (status === 200) {
					resolve(payload);
				}
				else {
					reject(request);
				}
			}, { dummy: false });
		});
	}
}

export default userServices;

// User Utilities
const updateUserData = (user, prevData = false) => {
	let userData = extend({}, {
		name: 'Kullanıcı',
		//printer_ip: 'AC:3F:A4:F2:03:CD',
	}, user);

	let token = userData.auth_token ? userData.auth_token : (prevData && prevData.authToken ? prevData.authToken : false);

	let appState = {
		user: userData,
		authToken: token,
	};

	localStorage["appState"] = JSON.stringify(appState);
	store.dispatch(setToken(token));
	store.dispatch(setUserData(userData));
}