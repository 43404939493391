import React, { useState, useEffect } from 'react'

// Deps
import formatDate from 'functions/format-date'
import { openModal } from 'utils/modals'

// Partials
import Btn from 'views/partials/btn'
import Loader from 'views/partials/loader'

// Services
import saleServices from 'services/sale'

const ShipmentList = () => {
	const [sales, setSales] = useState(null);

	const getSales = () => {
		setSales(null);
		saleServices.findLoadingSale().then((payload) => {
			setSales(payload.sales);
		}).catch(() => {
			setSales(false);
		});
	}

	const saleUpdated = () => {
		getSales();
	}

	const selectSale = (sale) => {
		openModal('loadingComplete', { sale: sale, onComplete: saleUpdated });
	}

	useEffect(() => {
		getSales();
	}, [])

	return (
		<>
			<div className="section sale-search-results loader-container">
				<div className="wrapper">
					<Loader inner loading={sales === null} />
					{!!sales &&
						<>
							{sales.length > 0 ?
								<ul className="listing">
									{sales.map((sale) => (
										<div className="listing-item" key={sale.id}>
											<div className="item-field">
												<strong>Satış Kodu</strong>
												<span>{sale.sale_code}</span>
											</div>
											<div className="item-field">
												<strong>Müşteri Adı</strong>
												<span>{sale.sale_client}</span>
											</div>
											<div className="item-field">
												<strong>Durum</strong>
												<span>{sale.status_text}</span>
											</div>
											<div className="item-field">
												<strong>Ödeme Yöntemi</strong>
												<span>{sale.sale_payment_name}</span>
											</div>
											<div className="item-field">
												<strong>Tutar</strong>
												<span>{sale.total} TL</span>
											</div>
											<div className="item-field">
												<strong>Teslimat Saati</strong>
												<span className="date">
													<span>{formatDate(sale.delivery_calendar_date, 'DD MMMM YYYY')} - {sale.delivery_calendar_hour}</span>
												</span>
											</div>
											<div className="item-controls">
												<Btn
													low
													onClick={() => { selectSale(sale); }}
													className="success">
													Tamamla
												</Btn>
											</div>
										</div>
									))}
								</ul>
								:
								<div className="listing-message">
									Kontrol bekleyen sipariş yok.
								</div>
							}
						</>
					}
				</div>
			</div>
		</>
	)
}

export default ShipmentList