import React, { useState } from 'react'

// Partials
import Btn from 'views/partials/btn'
import { FormInput, InputForm } from 'views/partials/forms'

// Deps
import { connect } from "react-redux"
import { closeModal } from 'utils/modals'

// Functions
import print from 'functions/print'

// Services
import saleServices from 'services/sale'

const mapStateToProps = state => {
	return {
		terminalDevice: state.site.terminalDevice
	};
};

const PlanningCompleteModal = ({ sale, className, onComplete }) => {
	const [printing, setPrinting] = useState(false);

	const submit = (formFields) => {
		setPrinting(true);
		saleServices.getPlanningReceipt(sale.id, formFields).then((html) => {
			print(html).then(() => {
				saleServices.finishPlanningSale(sale.id).then(() => {
					setPrinting(false);
					onComplete();
					closeModal();
				}).catch(() => {
					setPrinting(false);
				})
			});

		}).catch(() => {
			setPrinting(false);
		})
	}

	return (
		<div className={className}>
			<InputForm className="modal-innercontent" onSubmit={submit}>
				<h2 className="opts-title">Poşet Fişi Yazdır</h2>

				<div className="complete-bag">
					<strong>Poşet</strong>
					<FormInput
						className="controls-counter"
						name="count_bag"
						type="counter"
						max={20} />
				</div>
				<div className="complete-bag">
					<strong>Kasa</strong>
					<FormInput
						className="controls-counter"
						name="count_container"
						type="counter"
						max={20} />
				</div>
				<div className="complete-bag">
					<strong>Soğuk</strong>
					<FormInput
						className="controls-counter"
						name="count_cold"
						type="counter"
						max={20} />
				</div>
				<div className="complete-bag">
					<strong>Temizlik</strong>
					<FormInput
						className="controls-counter"
						name="count_hygiene"
						type="counter"
						max={20} />
				</div>

				<Btn
					className="complete-cta block success"
					type="submit"
					disabled={printing}>
					Yazdır ve Tamamla
				</Btn>
				<Btn
					className="complete-cta block error"
					disabled={printing}
					onClick={closeModal}>
					Kapat
				</Btn>
			</InputForm>
		</div>
	)
}

const ConnectedModal = connect(mapStateToProps)(PlanningCompleteModal);

ConnectedModal.defaultProps = {
	className: "",
	containerClass: "modal-sale-planning-complete",
	name: "planningComplete",
	preventClose: true,
	//
	sale: false,
}

export default ConnectedModal;