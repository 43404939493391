import React, { useState } from 'react'

// Partials
import Btn from 'views/partials/btn'
//import { FormInput } from 'views/partials/forms'

// Sections
//import BarcodeInput from 'views/sections/barcode-input'

// Deps
import { connect } from "react-redux"
import { closeModal } from 'utils/modals'
// import saleServices from 'services/sale'
// import print from 'functions/print'

// Assets

const mapStateToProps = state => {
	return {
		terminalDevice: state.site.terminalDevice
	};
};

const ProductCollectionCompleteModal = (props) => {
	// const [deliveryReceiptPrinted, setDeliveryReceiptPrinted] = useState(false);
	// const [bagReceiptPrinted, setBagReceiptPrinted] = useState(false);
	// const [loading, setLoading] = useState(false);
	const [printing/*, setPrinting*/] = useState(false);

	// const printDeliveryReceipt = () => {
	// 	setPrinting(true);
	// 	saleServices.getDeliveryReceipt(props.saleID).then((html) => {
	// 		print(html).then(() => {
	// 			setDeliveryReceiptPrinted(true);
	// 			setPrinting(false);
	// 		});

	// 	}).catch(() => {
	// 		setPrinting(false);
	// 	})
	// }

	// const printBagReceipt = () => {
	// 	setPrinting(true);
	// 	saleServices.getBagReceipt(props.saleID).then((html) => {
	// 		print(html).then(() => {
	// 			// setBagReceiptPrinted(true);
	// 			setPrinting(false);
	// 		});

	// 	}).catch(() => {
	// 		setPrinting(false);
	// 	})
	// }

	const finish = () => {
		props.onComplete();
		closeModal();
	}

	return (
		<div className={props.className}>
			<div className="modal-innercontent">
				<h2 className="opts-title">Toplama İşlemi Başarılı</h2>

				{/* <Btn
					disabled={printing}
					className="opts-opt block"
					onClick={printDeliveryReceipt}>
					Teslimat Fişi Yazdır
				</Btn> */}

				{/* <Btn
					disabled={printing}
					className="opts-opt block"
					onClick={printBagReceipt}>
					Poşet Fişi Yazdır
				</Btn> */}

				<Btn
					className="opts-opt block success"
					// disabled={!deliveryReceiptPrinted || printing}
					disabled={printing}
					onClick={finish}>
					Tamam
				</Btn>
			</div>
		</div>
	)
}

const ConnectedModal = connect(mapStateToProps)(ProductCollectionCompleteModal);

ConnectedModal.defaultProps = {
	className: "",
	containerClass: "modal-product-opts",
	name: "productCollectionComplete",
	preventClose: true,
	//
	saleID: false,
}

export default ConnectedModal;