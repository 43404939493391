import { combineReducers, createStore } from 'redux'
import siteReducer from "store/site/index";
import userReducer from "store/user/index";
import modalsReducer from "store/modals/index";
import messengerReducer from "store/messenger/index";

const rootReducer = combineReducers({
	site: siteReducer,
	user: userReducer,
	modals: modalsReducer,
	messenger: messengerReducer,
})

const store = createStore(rootReducer);

export default store;