import React from 'react'

// Deps
import { Route, matchPath, Switch, Redirect } from 'react-router-dom'
import history from 'utils/history'
import { setTitle, setMeta, setHead, setDescription } from 'utils/head'
import routes from 'routes'
import store from "store/";
import { setPage, setPageNotFound } from "store/site/actions";
import extend from "lodash/extend";
import { connect } from "react-redux";

// Sections
import Header from 'views/sections/header'

// Partials

// Modals
import ProductCollectionOptsModal from 'views/modals/product-collection-opts'
import ProductCollectionCompleteModal from 'views/modals/product-collection-complete'
import ProductDeliveryCompleteModal from 'views/modals/product-delivery-complete'

import ProductReturnOptsModal from 'views/modals/product-return-opts'

import PlanningCompleteModal from 'views/modals/sale-planning-complete'

import LoadingCompleteModal from 'views/modals/sale-loading-complete'

import BarcodeReaderModal from 'views/modals/barcode-reader'

// Controllers
import DomWatcher from 'controllers/dom-watcher'
import ModalsWrap from 'controllers/modals-wrap'
import Messenger from 'controllers/messenger'
import ErrorLogger from 'controllers/error-logger'

// Pages
import Login from 'views/pages/login'
import Dashboard from 'views/pages/dashboard'
import KeyListener from 'views/pages/key-listener'

import CollectionList from 'views/pages/collection-list'
import CollectionDetail from 'views/pages/collection-detail'

import PlanningList from 'views/pages/planning-list'
import PlanningDetail from 'views/pages/planning-detail'

import ShipmentList from 'views/pages/shipment-list'

import DeliveryList from 'views/pages/delivery-list'
import DeliveryDetail from 'views/pages/delivery-detail'
import DeliveryDetailReturns from 'views/pages/delivery-detail/delivery-detail-returns'

import LoadList from 'views/pages/load-list'

import ReturnList from 'views/pages/return-list'
import ReturnDetail from 'views/pages/return-detail'

import NotFound from 'views/pages/notfound'

const pageRegistry = {
	Login: Login,
	Dashboard: Dashboard,
	KeyListener: KeyListener,

	CollectionList: CollectionList,
	CollectionDetail: CollectionDetail,

	PlanningList: PlanningList,
	PlanningDetail: PlanningDetail,

	ShipmentList: ShipmentList,
	
	DeliveryList: DeliveryList,
	DeliveryDetail: DeliveryDetail,
	DeliveryDetailReturns: DeliveryDetailReturns,

	LoadList: LoadList,

	ReturnList: ReturnList,
	ReturnDetail: ReturnDetail,

	NotFound: NotFound,
}

const mapStateToProps = state => {
	return {
		currentPage: state.site.currentPage,
		userData: state.user.data,
	};
};

class Navigator extends React.Component {
	constructor(props) {
		super(props);

		changePage();

		this.currentPathname = null;
    	this.currentSearch = null;

    	this.backEvent = document.createEvent('Event');
		this.backEvent.initEvent('goBack', true, true);
	}

	componentDidMount() {
		let vm = this;
		window.dynamicHistory = history;
		window.historyStates = 0;

		history.listen((newLocation, action) => {
			window.historyStates = window.historyStates ? window.historyStates + 1 : 1;
			let curPage = vm.props.currentPage.data;
			let route = getRouteFromUrl(newLocation.pathname, true, true);
			if (action === "PUSH") {
				if (
		        	(newLocation.pathname !== vm.currentPathname || newLocation.search !== vm.currentSearch ) &&
		        	route.preventBack
		        ){
					vm.currentPathname = newLocation.pathname;
          			vm.currentSearch = newLocation.search;
					
					history.push({
						pathname: newLocation.pathname,
						search: newLocation.search
					});
				}
				changePage(route.key, route.groupKey);
			}
			else {
				if(curPage.preventBack && history.location.pathname === vm.currentPathname){
					history.go(1);
					window.dispatchEvent(vm.backEvent);
				}
				else {
					changePage(route.key, route.groupKey);
				}
			}
		});
	}

	render() {
		let renderElem = false;

		if(this.props.currentPage.data.loginRedirect && this.props.userData !== false){
			renderElem = <Redirect to={this.props.currentPage.data.loginRedirect} />
		}
		else if(this.props.currentPage.key && !(this.props.currentPage.data.requiresLogin === true && this.props.userData === false)){
			renderElem = <React.Fragment>
				<div className="site-content">
					{!this.props.currentPage.data.hideHeader &&
						<Header />
					}
					<React.Fragment>
						{renderRoutes()}
					</React.Fragment>
				</div>
			</React.Fragment>
		}
		else if(this.props.currentPage.key) {
			renderElem = <Redirect to='/login' />
		}

		return (
			<React.Fragment>
				<ModalsWrap>
					<ProductCollectionOptsModal />
					<ProductCollectionCompleteModal />
					<ProductDeliveryCompleteModal />
					<ProductReturnOptsModal />
					<PlanningCompleteModal />
					<LoadingCompleteModal />
					<BarcodeReaderModal />
				</ModalsWrap>
				<Messenger />
				<ErrorLogger />
				<DomWatcher />
				{renderElem}
			</React.Fragment>
		)
	}
}

export default connect(mapStateToProps)(Navigator);

export function redirect(opts, params = false, getParams = false) {
	const defaultOpts = {
		type: 'push'
	}

	opts = (Object.prototype.toString.call(opts) === "[object String]" ? extend({}, defaultOpts, { to: opts }) : extend({}, defaultOpts, opts));

	let route = getRoute(opts.to).path;

	if (route) {
		if (params) {
			for (let k = 0; k < Object.keys(params).length; k++) {
				let key = Object.keys(params)[k];
				route = route.replace(':' + key + '?', params[key]).replace(':' + key, params[key]);
			}
		}

		let getString = "";
		if (getParams) {
			for (let p = 0; p < Object.keys(getParams).length; p++) {
				let key = Object.keys(getParams)[p];

				if (getString !== "") {
					getString += "&";
				}
				getString += key + "=" + encodeURIComponent(getParams[key]);
			}
		}

		route = route.split('/:')[0];

		if (getString !== "") { route = route + "?" + getString }
		switch (opts.type) {
			case "replace":
				history.replace(route);
				break;
			default:
				history.push(route);
				break;
		}
		changePage();
		return true;
	}
	else {
		return false;
	}
}

export function getRoute(key = false, group = 'pages') {
	let routeGroup = group;
	if(key){
	let keyParts = key.split('.');
		if (keyParts.length === 2) {
			routeGroup = keyParts[0];
			key = keyParts[1];
		}
	}

	let target = routes[routeGroup][key];
	return (target ? target : false);
}

export function getRouteFromUrl(url = false, getObject = false, includeCatch = false) {
	if (url === false) { url = window.location.pathname.replace(/\/$/, ''); }
	let returnRoute = false;
	let catchRoute = false;

	for(const groupKey in routes){
		for(const key in routes[groupKey]){
			let route = routes[groupKey][key];
			if (route.path) {
				if(!returnRoute){
					let match = matchPath(url, route.path);
					if (match && match.isExact) {
						if (getObject) {
							returnRoute = route;
							returnRoute.key = key;
							returnRoute.groupKey = groupKey;
						}
						else {
							returnRoute = [key, groupKey];
						}
					}
				}
			}
			else if (includeCatch) {
				if (getObject) {
					catchRoute = route;
					catchRoute.key = key;
					catchRoute.groupKey = groupKey;
				}
				else {
					catchRoute = [key, groupKey];
				}
			}
		};
	};

	return (returnRoute ? returnRoute : catchRoute);
}

export function getCurrentRoute(url = false, includeCatch = true) {
	return getRouteFromUrl(false, true, true);
}

export function changeURLParam(value, param, route = false, noMismatch = false) {
	let routeObject = (route === false ? getCurrentRoute() : routes[route]);
	let data = false;

	if (routeObject) {
		data = routeObject.path.replace(':' + param + '?', value).replace(':' + param, value);
		if (noMismatch && data === routeObject.path) {
			data = false;
		}
	}

	return data;
}

export function changePage(key = false, group = 'pages') {
	let route = (key ? routes[group][key] : getRouteFromUrl(false, true, true));

	if(route) {
		if (route.key) {
			key = route.key;
			group = route.groupKey;

		}

		if(key === 'notfound') {
			store.dispatch(setPageNotFound(true));
		}

		let pageData = {
			key: key,
			group: group,
			fullKey: group + "." + key,
			data: route
		}

		if (store.getState().site.currentPage.key !== key) {
			window.scroll(0, 0);
			store.dispatch(setPage(pageData));

			if (window.location.hash) {
				setTimeout(function () {
					let hashTarget = document.querySelector(window.location.hash)
					if (hashTarget) {
						hashTarget.scrollIntoView();
					}
				}, 500);
			}
		}
		
		setMeta((route.meta ? route.meta : false), true);
		setHead((route.head ? route.head : false), true);

		setTitle(route.title, route.postTitle);
		
		if (route.description) {
			setDescription(route.description);
		}

		setHead([
			{
				key: "link",
				props: {
					rel: "canonical",
					href: window.location.href,
				}
			},
			{
				key: "meta",
				props: {
					property: "og:url",
					content: window.location.href,
				}
			}
		]);
	}
	else {
		console.warn('Navigator error', 'Route not found: ' + key)
	}
}

export function renderRoutes(opts = {}) {
	const defaultOpts = {
		registry: pageRegistry,
		group: 'pages',
		catchRedirect: false,
	}
	opts = extend({}, defaultOpts, opts);
	let routeData = Object.keys(routes[opts.group]).map((key, index) => {
		let route = routes[opts.group][key]
		let routeProps = {
			key: index,
			exact: route.exact,
			component: opts.registry[route.component],
			name: route.component
		}

		if (route.path) {
			routeProps.path = route.path
		}

		return <Route {...routeProps} />
	});

	if (opts.catchRedirect) {
		let catchOpts = opts.catchRedirect.split('.');
		let to = routes[(catchOpts.length > 1 ? catchOpts[0] : 'pages')][catchOpts[catchOpts.length - 1]].path;
		routeData.push(<Redirect to={to} key="redir" />)
	}

	return <Switch>{routeData}</Switch>;
}

export function set404() {
	changePage('notfound');
}