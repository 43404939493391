import React, { useEffect, useRef, useCallback, useState } from 'react'

// Deps
import { connect } from 'react-redux'
import Quagga from 'quagga'
import { closeModal } from 'utils/modals'

// Functions
// import formatDate from 'functions/format-date';

// Partials
// import { InputForm, FormInput } from 'views/shared/partials/forms'
import Btn from 'views/partials/btn'

const metas = document.getElementsByTagName('meta');

const gestureStart = () => {
	for (let i = 0; i < metas.length; i++) {
		if (metas[i].name === "viewport") {
			metas[i].content = "width=device-width, minimum-scale=0.25, maximum-scale=1.6";
		}
	}
}

const checkIphoneFix = () => {
	if (navigator.userAgent.match(/iPhone/i)) {
		for (let i = 0; i < metas.length; i++) {
			if (metas[i].name === "viewport") {
				metas[i].content = "width=device-width, minimum-scale=1.0, maximum-scale=1.0";
			}
		}
		document.addEventListener("gesturestart", gestureStart, false);
	}
}

const ProductBarcodeReader = ({ onBarcodeRead, ...props }) => {
	const scanned = useRef(false);

	const barcodeFound = useCallback((barcode) => {
		if(!scanned.current) {
			scanned.current = true;
			onBarcodeRead(barcode);
			closeModal();
		}
	}, [onBarcodeRead]);

	useEffect(() => {
		checkIphoneFix();

		return () => {
			document.removeEventListener("gesturestart", gestureStart, false);
		}
	}, [])

	return (
		<div className={props.className}>
			<div className="modal-header">
				<h1 className="header-title">Barkodu Okutun</h1>
			</div>
			<div className="reader-content">
				<ConnectedBarcodeScanner
					className="content-reader"
					onSuccess={barcodeFound}
					onError={closeModal}
				/>
				<Btn
					wide
					className="reader-close"
					onClick={closeModal}>
					Vazgeç
				</Btn>
			</div>
		</div>
	)
}

ProductBarcodeReader.defaultProps = {
	className: '',
	containerClass: 'modal-barcode-reader',
	name: 'barcodeReader',
	title: false,
	content: false,
	source: false,
}

export default ProductBarcodeReader

const mapStateToProps = state => {
	return {
		windowWidth: state.site.windowWidth,
		windowHeight: state.site.windowHeight,
	};
};

const BarcodeScanner = ({ onSuccess, onError, windowWidth, windowHeight, ...props }) => {
	const scannerActive = useRef(false);
	const scannerElem = useRef();
	const cameraInstance = useRef(false);

	const [torchAvailable, setTorchAvailable] = useState(false);
	const [torchEnabled, setTorchEnabled] = useState(false);

	const getCapabilities = useCallback(() => {
		if (cameraInstance.current && typeof cameraInstance.current.getCapabilities === 'function') {
			let capabilities = cameraInstance.current.getCapabilities();

			setTorchAvailable(capabilities.torch);
			// setTorchAvailable(true);
		}
	}, []);

	const stopScanner = useCallback(() => {
		Quagga.stop();
	}, []);

	const startScanner = useCallback((width, height) => {
		if (scannerActive.current) {
			stopScanner();
		}

		const getMedian = (arr) => {
			arr.sort((a, b) => a - b);
			const half = Math.floor(arr.length / 2);
			if (arr.length % 2 === 1) {
				return arr[half];
			}
			return (arr[half - 1] + arr[half]) / 2;
		}
		
		const getMedianOfCodeErrors = (decodedCodes) => {
			const errors = decodedCodes.filter(x => x.error !== undefined).map(x => x.error);
			const medianOfErrors = getMedian(errors);
			return medianOfErrors;
		}

		Quagga.init({
			inputStream: {
				type: 'LiveStream',
				name: 'Live',
				constraints: {
					width: { min: 720 },
					height: { min: 720 },
					// aspectRatio: { min: 1, max: 100 },
					facingMode: 'environment'
				},
				numberOfWorkers: navigator.hardwareConcurrency,
				target: scannerElem.current,
			},
			// locator: {
			// 	patchSize: 'medium',
			// 	halfSample: true
			// },
			locate: false,
			decoder: {
				readers: [
					{
						format: 'ean_reader',
						config: {}
					}
				]
			},
			// locate: true
		}, (error) => {
			if (!error) {
				Quagga.start();

				cameraInstance.current = Quagga.CameraAccess.getActiveTrack()
				getCapabilities();

				Quagga.onDetected(function (result) {
					
					if(result.codeResult.direction === 1 && result.codeResult.format === 'ean_13') {
						const errorAvg = getMedianOfCodeErrors(result.codeResult.decodedCodes);
						if(errorAvg < 0.08) {
							onSuccess(result.codeResult.code);
						}
					}
				});
			}
			else {
				onError(error);
				console.warn(error);
			}
		});
	}, [stopScanner, onSuccess, onError, getCapabilities]);

	useEffect(() => {
		startScanner(windowWidth, windowHeight);
	}, [windowWidth, windowHeight, startScanner])

	useEffect(() => {
		try {
			if (cameraInstance.current && typeof cameraInstance.current.getCapabilities === 'function') {
				cameraInstance.current.applyConstraints({ advanced: [{ torch: torchEnabled ? true : false }] });
			}
		}
		catch (e) {
			alert(e);
		}
	}, [torchEnabled])

	useEffect(() => {
		return (() => {
			stopScanner();
		})
	}, [stopScanner])

	return (
		<div className="reader-scanner">
			<div key="scannercam" ref={scannerElem} className="scanner-camera">
			</div>
			<div className="scanner-controls">
				{torchAvailable &&
					<Btn
						big
						onClick={() => { setTorchEnabled(!torchEnabled) }}
						blue={torchEnabled ? undefined : true}
						white={!torchEnabled ? undefined : true}
						icon={torchEnabled ? 'torch-on' : 'torch-off'} />
				}
			</div>
		</div>
	)
}

const ConnectedBarcodeScanner = connect(mapStateToProps)(BarcodeScanner);