import request from 'utils/request'

const siteServices = {
	getDashboard: () => {
		return new Promise((resolve, reject) => {
			request.get('notifications', false, function(payload, status, request){
				if(payload) {
					resolve(payload.notifications);
				}
				else {
					reject(request);
				}
			});
		});
	}
}

export default siteServices;