const initialState = {
	token: false,
	data: false,
	unreadMessageCount: 0,
};

const userReducer = (state = initialState, action) => {
	if (action.type === "SET_USER_DATA") {
		return Object.assign({}, state, {
			data: action.payload
		});
	}
	else if (action.type === "SET_TOKEN") {
		return Object.assign({}, state, {
			token: action.payload
		});
	}
	return state;
}

export default userReducer;