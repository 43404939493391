import React, { useState, useRef, useEffect, useCallback } from 'react'

import useDeboucne from 'hooks/debounce';

import { connect } from 'react-redux';
// Deps

// Partials
import Btn from 'views/partials/btn'
import { openModal } from 'utils/modals'
import saleServices from 'services/sale';

const mapStateToProps = state => {
	return {
		mobile: state.site.mobile,
	};
};

const BarcodeInput = ({ noWrap, disabled, numeric, onSubmit, autoClear, onKeyEvent, saleID, dynamic, placeholder, manualPlaceholder, mobile }) => {
	const _mounted = useRef(false);

	const valueRef = useRef('');
	const [value, setRawValue] = useState(valueRef.current);
	const [manualMode, setManualMode] = useState(!mobile);

	const debouncedValue = useDeboucne(value, 300);

	const setValue = (val) => {
		valueRef.current = val;
		setRawValue(valueRef.current);
	}

	const submitRaw = (e) => {
		e.preventDefault();
		read(value);
	}

	const opticRead = (barcode) => {
		read(barcode);
	}

	const read = useCallback((barcode) => {
		if(_mounted.current && !disabled && onSubmit && barcode.length > 1){
			if(dynamic && saleID) {
				saleServices.checkBarcode(saleID, barcode).then(({ erpCode, calculatedQuantity }) => {
					try {
						onSubmit(erpCode, calculatedQuantity);
						if(autoClear && _mounted.current){
							setValue('');
						}
					}
					catch(e) {
						openModal('warning', {
							message: 'Barkod okutulurken bir hata ile karşılaşıldı.'
						})
					}
				}).catch((e) => {
					openModal('warning', {
						message: `<strong>"${barcode}"</strong> barkodlu ürün bulunamadı.`
					});
					setValue('');
				})
			}
			else {
				onSubmit(barcode);
				if(autoClear) {
					setValue('');
				}
			}
		}
	}, [disabled, dynamic, saleID, autoClear]);  // eslint-disable-line react-hooks/exhaustive-deps

	const keyTrigger = useCallback((e) => {
		if(!manualMode) {
			if(onKeyEvent) {
				onKeyEvent(e);
			}
			if(e.key.length === 1 && !e.altKey && !e.ctrlKey && !e.metaKey && !e.shiftKey) {
				setValue(valueRef.current + e.key);
			}
		}
	}, [manualMode, onKeyEvent]);

	useEffect(() => {
		setValue('');
	}, [manualMode])

	useEffect(() => {
		_mounted.current = true;
		document.removeEventListener('keydown', keyTrigger);
		document.addEventListener('keydown', keyTrigger);
		
		return(() => {
			document.removeEventListener('keydown', keyTrigger);
			_mounted.current = false;
		})
	}, [keyTrigger])

	useEffect(() => {
		if(!manualMode && debouncedValue.length > 2) {
			read(debouncedValue);
		}
	}, [debouncedValue, manualMode, autoClear]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<form className={`section barcode-reader${noWrap ? '' : ' wrapped'}`} onSubmit={submitRaw}>
			<div className={(noWrap ? '' : 'wrapper ') + 'reader-wrap'}>
				<button
					type="button"
					className="reader-modebtn"
					onClick={() => { setManualMode(!manualMode) }}>
					<i className="icon-switch"></i>
				</button>
				<div className="reader-inputwrap">
					<i className={"inputwrap-icon reader-modeicon " + (manualMode ? 'icon-pin' : 'icon-barcode')}></i>
					<input
						autoFocus={disabled ? false : true}
						disabled={disabled && true}
						readOnly={!manualMode && true}
						onChange={(e) => { setValue(e.target.value); }}
						type={numeric ? 'number' : 'text'}
						placeholder={manualMode ? manualPlaceholder : placeholder}
						value={value}
						data-keyboard={manualMode ? undefined : "false"}
						className="reader-input">
					</input>
					{manualMode ?
						<button
							type="submit"
							className="inputwrap-icon reader-cta"> 
							<i className="icon-arrow-right"></i>
						</button>
						:
						<Btn
							icon="barcode"
							wide
							className="small text reader-opticcta"
							onClick={() => {
								openModal('barcodeReader', { onBarcodeRead: opticRead })
							}} />
					}
				</div>
			</div>
		</form>
	);
}

BarcodeInput.defaultProps = {
	onSubmit: false,
	autoClear: true,
	numeric: false,
	disabled: false,
	noWrap: false,
	manualPlaceholder: 'El ile barkod giriniz',
	placeholder: 'Barkod okutunuz'
}
export default connect(mapStateToProps)(BarcodeInput);