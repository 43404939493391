import React, { useState, useEffect, useMemo } from 'react'
import config from 'config'

// Partials
import Loader from 'views/partials/loader'
import Btn from 'views/partials/btn'
import Image from 'views/partials/image'
// import Link from 'views/partials/link'

// Sections
import SaleInfo from 'views/sections/sale-info'

// Deps
import { openModal } from 'utils/modals'
import saleServices from 'services/sale'
import { redirect } from 'controllers/navigator'
import getCdnImage from 'functions/get-cdn-image'
import formatNumber from 'functions/format-number'
// import config from 'config'

const DeliveryDetail = (props) => {
	const [sale, setSale] = useState(null);

	useEffect(() => {
		setSale(null);
		saleServices.getDeliverySale(props.match.params.id).then((sale) => {
			if(sale.status !== 21) {
				redirect('dashboard');
			}
			else {
				setSale(sale);
			}
			// if(sale.status === 2) {
			// 	redirect('collectionDetail', {id: sale.id});
			// }
			// else {
			// 	setSale(sale);
			// 	// openModal('warning', {
			// 	// 	message: `Bu sipariş "${sale.status_text}" statüsünde.`
			// 	// });
			// 	// setSale(false);
			// }
		}).catch(() => {
			setSale(false);
		});
	}, [props.match.params.id])

	const saleProducts = useMemo(() => {
		if(!sale?.product_groups) {
			return false;
		}
		return sale.product_groups.reduce((prods, group) => {
			return [...prods, ...group.products.filter(p => p.product_erp_code !== config.deliveryProductErpCode)];
		}, []);
	}, [sale]);

	const completeSale = () => {
		openModal(
			'planningComplete',
			{
				sale: sale,
				onComplete: () => {
					redirect('planningList');
				}
			}
		)
	}

	const rejectSale = () => {
		openModal('confirm', {
			message: 'Siparişi toplamaya geri göndermek istediğinizden emin misiniz?', onConfirm: () => {
				saleServices.setSaleStatus(sale.id, 1).then(() => {
					redirect('planningList');
				}).catch(() => {})
			}
		})
	}

	return (
		<div className="page sale loader-container">
			<Loader inner loading={sale === null} />
			{(sale !== null && sale !== false) ?
				<React.Fragment>
					<SaleInfo sale={sale} />

					<div className="section sale-products">
						{!!saleProducts?.length ? 
							<div className="products-group">
								<div className="group-head">
									<div className="wrapper head-wrap">
										Ürünler
									</div>
								</div>
								<div className="group-products">
									<div className="wrapper">
									{saleProducts.map((product, nth) => {
										return (
											<div
												className="products-product"
												key={nth}>
												<Image
													bg contain
													src={getCdnImage(product.image, { width: 100, height: 100 })}
													className="product-image" />

												<div className="product-text">
													<div className="text-maincontent">
														<strong className="product-title">{product.product_name}</strong>
													</div>
													<div className="product-bottominfo">
														<div className="product-price">{formatNumber(product.product_price)} TL</div>
														<div className="product-quantity">
															<p className="quantity-num total">
																{product.quantity.collected} {product.quantity_type_text}
															</p>
															{product.quantity.return > 0 &&
																<p className="quantity-num sub returned">(İade Edilen: {product.quantity.return} {product.quantity_type_text})</p>
															}
														</div>
													</div>
												</div>
											</div>
										)
									})}
									</div>
								</div>
							</div>
							:
							<div className="wrapper">
								<div className={'products-message'}>
									Bu siparişte iade edilebilecek ürün bulunmuyor.
								</div>
							</div>
						}
					</div>

					<div className="section sale-controls">
						<Btn
							onClick={rejectSale}
							className="controls-btn error wide">
							Toplamaya Geri Gönder
						</Btn>
						<Btn
							className="controls-btn success wide"
							onClick={completeSale}>
							Sevkiyata Gönder
						</Btn>
					</div>
				</React.Fragment>
				:
				<React.Fragment>
				{ sale !== null &&
					<div className="wrapper">
						<div className="section sale-message">
							Sipariş bulunamadı.
						</div>
					</div>
				}
				</React.Fragment>
			}
		</div>
	)
}

export default DeliveryDetail