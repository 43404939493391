// Deps
// import extend from "lodash/extend"

// Config
import devConfig from "config/dev"
import prodConfig from "config/prod"
import pstvConfig from "config/pstv"
import preprodConfig from "config/preprod"

const defaults = {
	environment: 'default',
	titlePostFix: 'Metro Terminal',
	mobileBreakPoint: 960,
	maskedCallNumber: '08505326149,125',
	sentryDSN: false, //'https://9afc6f91208b4649abae9ed10198585d@debug.positive.com.tr/7',
	cdnURL: 'https://cdn.metro-group.com/tr/',
	excessibleAmountMultiplier: 1.5,
	quantityDecimals: 3,
	bagProductErpCode: 335935,
	deliveryProductErpCode: 371557,
}

let env = process.env.REACT_APP_CONFIG_ENV ? process.env.REACT_APP_CONFIG_ENV : process.env.NODE_ENV;

let config = {};

switch(env) {
	case "production":
		config = {...defaults, ...prodConfig};
	break;
	case "pstv":
		config = {...defaults, ...devConfig, ...pstvConfig};
	break;
	case "preprod":
		config = {...defaults, ...devConfig, ...preprodConfig};
	break;
	default:
		config = {...defaults, ...devConfig};
	break;

}

if(process.env.REACT_APP_API_URL) { config.apiURL = process.env.REACT_APP_API_URL; }

if (env !== 'production') {
	window.dumpConfig = () => {
		console.log('Environment Dump:', env);
		return config;
	}
}

export default config;