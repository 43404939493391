// Deps
import config from 'config';
import { parsePhoneNumberFromString } from 'libphonenumber-js'

export function validation(value, controls = ['required']) {
	let error = false;

	if (controls === true) {
		controls = ['required'];
	}
	// Shorthand for required message
	else if (typeof controls === 'string' || controls instanceof String) {
		controls = [
			['required', controls]
		];
	} else if (!Array.isArray(controls)) {
		controls = Object.keys(controls).map(function (objectKey, index) {
			let data = [controls[objectKey]];
			if (Array.isArray(data[0])) { data = data[0]; }

			return [objectKey, ...data];
		});
	}

	controls.reverse();

	controls.map((control) => {
		let rerror;
		let key = control;
		let msg = false;
		let data = false;
		if (Array.isArray(control)) {
			key = control[0];
			msg = (control[1] !== true ? control[1] : undefined);
			if (control.length > 1) {
				data = control[2];
			}
		}

		if (validateRaw[key]) {
			if (data !== false) {
				rerror = validateRaw[key](msg, value, data);
			} else {
				rerror = validateRaw[key](msg, value);
			}
		}
		else { rerror = "Hatalı validation: " + key; }

		if (rerror !== undefined) {
			error = rerror;
		}

		return error;
	});

	return error;
}

const validateRaw = {
	"email": function (msg = "You must enter a valid email address.", value) {
		let error;
		if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
			error = msg;
		}
		return error;
	},
	"fullName": function (msg = "You must enter a valid full name.", value) {
		let error;
		let split = value.split(' ');
		if (/\d/.test(value) || split.length < 2 || split[0].length < 2 || split[1].length < 2 || value.replace(' ', '').length < 4) {
			error = msg;
		}
		return error;
	},
	"text": function(msg = "This field shouldn't have numeric characters.", value) {
		let error;
		if (/\d/.test(value)) {
			error = msg;
		}
		return error;
	},
	"phone": (msg = "Geçerli bir telefon numarası girmelisiniz.", value) => {
		let error;

		let cleanVal = value.match(/\d+/g);
		cleanVal = cleanVal ? (cleanVal.join('')) : '';

		// +90 statik olarak eklendi
		const phoneNumber = parsePhoneNumberFromString('+90' + cleanVal);
		if (!(phoneNumber && phoneNumber.isValid())) {
			error = msg;
		}
		return error;
	},
	"numeric": function(msg = "This field shouldn't have non-numeric characters.", value, decimals = 2) {
		let error;
		const replacedVal = value.replace(',', '.');
		const parsedVal = parseFloat(replacedVal);
		const valueParts = value.split(',');
		

		if(valueParts.length > 2) {
			error = msg;
		}
		else if(valueParts.length === 2 && valueParts[1].length > decimals) {
			error = msg;
		}
		else if (isNaN(parsedVal)) {
			error = msg;
		}
		else {
			var parsedValMatches = false;
			for(let k = 1; k <= config.quantityDecimals; k++) {
				if(parsedVal.toFixed(k) === replacedVal) {
					parsedValMatches = true;
				}
			}

			if(!parsedValMatches) {
				error = msg;
			}
		}
		return error;
	},
	"ID": function (msg = "You must enter a valid ID number.", value) {
		let error;
		if (!checkIDNum(value)) {
			error = msg;
		}
		return error;
	},
	"required": function (msg = "You must fill in this field.", value) {
		let error;
		if (!value) {
			error = msg;
		}
		return error;
	},
	"minNum": function (msg = "This field must be less than {amount}.", value, amount, allowFormat = true) {
		let error;
		if(allowFormat){ value = value.toString().replace(/[.,]/g, ''); }
		if (parseFloat(value) < amount) {
			error = msg.replace('{amount}', amount);;
		}
		return error;
	},
	"maxNum": function (msg = "This field must be more than {amount}.", value, amount, allowFormat = true) {
		let error;
		if(allowFormat){ value = value.toString().replace(/[.,]/g, ''); }
		if (parseFloat(value) > amount) {
			error = msg.replace('{amount}', amount);;
		}
		return error;
	},
	"minLength": function (msg = "This field must have at least {length} characters.", value, length) {
		let error;
		if (value && value.length < length) {
			error = msg.replace('{length}', length);;
		}
		return error;
	},
	"maxLength": function (msg = "This field must have maximum {length} characters.", value, length) {
		let error;
		if (value.length > length) {
			error = msg.replace('{length}', length);;
		}
		return error;
	},
	"minWords": function (msg = "This field must contain at least {length} words.", value, length) {
		let error;
		if (value.trim().split(' ').length < length) {
			error = msg.replace('{length}', length);;
		}
		return error;
	},
	"maxWords": function (msg = "This field must have maximum {length} words.", value, length) {
		let error;
		if (value.trim().split(' ').length > length) {
			error = msg.replace('{length}', length);;
		}
		return error;
	},
	"compare": function (msg = "Two fields do not match.", value, compare) {
		let error;
		let compareItem = document.querySelector(compare);
		if (!compareItem || compareItem.value !== value) {
			error = msg;
		}
		return error;
	},
	"fileRequired": function (msg = "You must fill in this field.", files) {
		let error;
		if (!files.length) {
			error = msg;
		}
		return error;
	},
	"date": function (msg = "You must enter a valid date.", value) {
		let error;
		if (!value) {
			error = msg;
		}
		return error;
	},
	"cvc": function (msg = "You must enter a valid CVC number.", value, length = false) {
		let error;
		let regString = /^([0-9]{3,4})$/g;
		if (length === 3) {
			regString = /^([0-9]{3})$/g;
		} else if (length === 4) {
			regString = /^([0-9]{4})$/g;
		}
		let regex = new RegExp(regString);
		if (!regex.test(value)) {
			error = msg;
		}
		return error;
	},
	"expiry": function (msg = "You must enter a valid expiration date.", dateString, gaps = false) {
		let error;


		var curYear = parseInt((new Date()).getFullYear().toString().substr(-2));
		var month = 13;
		var year = 1;
		var dateReg = new RegExp((gaps ? /[0-9]{1,2} \/ [0-9]{1,2}/ : /[0-9]{1,2}\/[0-9]{1,2}/));
		if (dateReg.test(dateString)) {
			var parts = dateString.split((gaps ? " / " : "/"));
			month = parseInt(parts[0], 10);
			year = parseInt(parts[1], 10);
		}

		if (month > 12 || year < 19 || year < (curYear)) {
			error = msg;
		}

		return error;
	},
}

function checkIDNum(tcno) {
	tcno = String(tcno);
	let j = 0;
	let i = 0;
	let hane_tek = 0;
	let hane_cift = 0;

	if (tcno.substring(0, 1) === '0') {
		return false;
	}
	if (tcno.length !== 11) {
		return false;
	}
	let ilkon_array = tcno.substr(0, 10).split('');
	let ilkon_total = hane_tek = hane_cift = 0;

	for (i = j = 0; i < 9; ++i) {
		j = parseInt(ilkon_array[i], 10);
		if (i & 1) { // tek ise, tcnin çift haneleri toplanmalı!
			hane_cift += j;
		} else {
			hane_tek += j;
		}
		ilkon_total += j;
	}
	if ((hane_tek * 7 - hane_cift) % 10 !== parseInt(tcno.substr(-2, 1), 10)) {
		return false;
	}
	ilkon_total += parseInt(ilkon_array[9], 10);
	if (ilkon_total % 10 !== parseInt(tcno.substr(-1), 10)) {
		return false;
	}
	return true;
};