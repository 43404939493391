import store from "store";
import { openModal as modalOpener, closeModal as modalCloser } from "store/modals/actions";

export function openModal(key, opts = {}) {
	let payload = { modal: key, ...opts };
	store.dispatch(modalOpener(payload));
}

export function closeModal(opts) {
	store.dispatch(modalCloser());
}