import request from 'utils/request'
import extend from 'lodash/extend'

const saleServices = {
	getSales: (inputQuery = {}) => {
		let query = extend({}, {
			//"status": 2,
			//"pagination": true,
			"query": "",
			//"page": 1,
			"order": "asc"
		}, inputQuery);

		return new Promise((resolve, reject) => {
			request.post(`collection/query-list`, query, (payload, status, request) => {
				if (status === 200) {
					resolve({
						sales: payload.sales,
						pagination: payload.pagination,
					});
				} else {
					reject(request);
				}
			}, {});
		});
	},
	findSale: (search) => {
		return saleServices.getSales({query: search});
	},
	pullSale: (saleID) => {
		return new Promise((resolve, reject) => {
			request.get(`collection/pull/${saleID}`, false, (payload, status, request) => {
				if (status === 200) {
					resolve(payload);
				} else {
					reject(request);
				}
			}, {});
		});
	},
	setSaleStatus: (id, status) => {
		return new Promise((resolve, reject) => {
			request.post(`sale/status/${id}/${status}`, false, function(payload, status, request){
				if(status === 200) {
					resolve();
				}
				else {
					reject(request);
				}
			});
		});
	},

	// Collection Ops
	getCollectionSale: (id) => {
		return new Promise((resolve, reject) => {
			request.get(`collection/detail/${id}`, false, (payload, status, request) => {
				if(payload) {
					const today = new Date();
					const todayDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
					const dateVals = payload.sale.delivery_calendar_date.split('-').map(n => parseInt(n));
					const saleDate = new Date(dateVals[0], dateVals[1] -1, dateVals[2]);

					resolve({
						...payload.sale,
						collectable: todayDate >= saleDate,
					});
				}
				else {
					reject(request);
				}
			});
		});
	},
	assignCollectionSale: (id) => {
		return new Promise((resolve, reject) => {
			request.get(`collection/assignee/${id}`, false, (payload, status, request) => {
				if(status === 200) {
					resolve(payload);
				}
				else {
					reject(request);
				}
			}, {});
		});
	},
	updateCollectionSale: (id, collectionData, bagData) => {
		return new Promise((resolve, reject) => {
			request.post(`collection/collect/${id}`, {products: collectionData, bags: bagData}, (payload, status, request) => {
				if(status === 200) {
					resolve(payload);
				}
				else {
					reject(request);
				}
			});
		});
	},
	startCollection: (id, reset = false) => {
		return new Promise((resolve, reject) => {
			request.get(`collection/start-collect/${id}?reset=${reset ? 'true' : 'false'}`, false, (payload, status, request) => {
				if (status === 200) {
					resolve(payload);
				}	
				else {
					reject(request);
				}
			});
		});
	},
	cancelCollection: (id) => {
		return new Promise((resolve, reject) => {
			request.get(`collection/cancel/${id}`, false, (payload, status, request) => {
				if (status === 200) {
					resolve(payload);
				}	
				else {
					reject(request);
				}
			});
		});
	},

	// Shipment Opts
	findShipmentSale: (search) => {
		return saleServices.getSales({query: search, status: [17, 61]});
	},
	submitShipmentList: (saleIDs) => {
		return new Promise((resolve, reject) => {
			request.post(`shipment/submit`, { sale_ids: saleIDs }, function(payload, status, request){
				if(status === 200) {
					resolve();
				}
				else {
					reject(request);
				}
			});
		});
	},

	// Control Opts
	findLoadingSale: (search) => {
		return saleServices.getSales({query: search, status: [70]});
	},
	getLoadingSale: (id) => {
		return new Promise((resolve, reject) => {
			request.get(`delivery/detail/${id}`, false, function(payload, status, request){
				if(payload) {
					resolve(payload.sale);
				}
				else {
					reject(request);
				}
			});
		});
	},
	submitLoadingSale: (id, formData) => {
		return new Promise((resolve, reject) => {
			try {
				request.post(`loading/submit/${id}`, formData, function(payload, status, request){
					if(status === 200) {
						resolve(true);
					}
					else {
						reject(request);
					}
				});
			}
			catch(e) {
				reject(false);
			}
		});
	},

	// Delivery Opts
	getDeliverySale: (id) => {
		return new Promise((resolve, reject) => {
			request.get(`delivery/detail/${id}`, false, function(payload, status, request){
				if(payload) {
					resolve(payload.sale);
				}
				else {
					reject(request);
				}
			});
		});
	},
	findDeliverySale: (query) => {
		return saleServices.getSales({query, status: 5});
	},
	findReturnSale: (query) => {
		return saleServices.getSales({query, status: 7});
	},
	finishDeliverySale: (id, info) => {
		return new Promise((resolve, reject) => {
			request.post(`delivery/finish/${id}`, info, function(payload, status, request){
				if(status === 200) {
					resolve();
				}
				else {
					reject(request);
				}
			});
		});
	},
	cancelDeliverySale: (id) => {
		return new Promise((resolve, reject) => {
			request.post(`delivery/cancel/${id}`, false, function(payload, status, request){
				if(status === 200) {
					resolve();
				}
				else {
					reject(request);
				}
			});
		});
	},

	// Planning
	finishPlanningSale: (id) => {
		return new Promise((resolve, reject) => {
			request.post(`planning/finish/${id}`, false, function(payload, status, request){
				if(status === 200) {
					resolve();
				}
				else {
					reject(request);
				}
			});
		});
	},

	// Receipts
	getDeliveryReceipt: (id) => {
		return new Promise((resolve, reject) => {
			request.get(`print/delivery/${id}`, false, (payload, status, request) => {
				if(payload && payload.html) {
					resolve(payload.html);
				}
				else {
					reject(request);
				}
			});
		});
	},
	getCollectionReceipt: (id) => {
		return new Promise((resolve, reject) => {
			request.get(`print/collection/${id}`, false, (payload, status, request) => {
				if(payload && payload.html) {
					resolve(payload.html);
				}
				else {
					reject(request);
				}
			});
		});
	},
	getBagReceipt: (id) => {
		return new Promise((resolve, reject) => {
			request.get(`print/bags/${id}`, false, (payload, status, request) => {
				if(payload && payload.html) {
					resolve(payload.html);
				}
				else {
					reject(request);
				}
			});
		});
	},
	getPlanningReceipt: (id, receiptData) => {
		return new Promise((resolve, reject) => {
			request.post(`print/planning/${id}`, receiptData, (payload, status, request) => {
				if(payload && payload.html) {
					resolve(payload.html);
				}
				else {
					reject(request);
				}
			});
		});
	},
	getReturnReceipt: (id) => {
		return new Promise((resolve, reject) => {
			request.get(`print/return/${id}`, false, (payload, status, request) => {
				if(payload && payload.html) {
					resolve(payload.html);
				}
				else {
					reject(request);
				}
			});
		});
	},

	// Package Fragment Ops
	getSalePackages: (id) => {
		return new Promise((resolve, reject) => {
			request.get(`package/packages/${id}`, false, function(payload, status, request){
				if(payload) {
					resolve(payload.packages);
				}
				else {
					reject(request);
				}
			});
		});
	},
	deleteSalePackage: (id) => {
		return new Promise((resolve, reject) => {
			request.get(`package/delete/${id}`, false, function(payload, status, request){
				if(status === 200) {
					resolve(request);
				}
				else {
					reject(request);
				}
			});
		});
	},
	updateSalePackage: (id, packageData) => {
		return new Promise((resolve, reject) => {
			request.post(`package/update/${id}`, {packaged_products: packageData}, function(payload, status, request){
				if(status === 200) {
					resolve(payload);
				}
				else {
					reject(request);
				}
			});
		});
	},

	// Return Opts
	returnProducts: (id, returnProds) => {
		return new Promise((resolve, reject) => {
			request.post(`return/${id}`, {products: { return: returnProds }}, function(payload, status, request){
				if(status === 200) {
					resolve(payload);
				}
				else {
					reject(request);
				}
			}, {});
		});
	},

	// Barcode
	checkBarcode: (saleID, barcode) => {
		return new Promise((resolve, reject) => {
			request.post(`collection/lookup/${saleID}`, { barcode: barcode }, function(payload, status, request){
				if(status === 200 && payload?.epr_code) {
					resolve({ erpCode: payload.epr_code, calculatedQuantity: payload.calculated_quantity});
				}
				else {
					reject(request);
				}
			}, { showMessages: false });
		});
	},
	// returnProducts: (id, returnProds) => {
	// 	return new Promise((resolve, reject) => {
	// 		request.post(`package/return/${id}`, {return_products: returnProds}, function(payload, status, request){
	// 			if(status === 200) {
	// 				resolve(payload);
	// 			}
	// 			else {
	// 				reject(request);
	// 			}
	// 		}, {});
	// 	});
	// },
}

export default saleServices;