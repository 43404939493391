import React, { useMemo, useRef } from 'react'

// Partials
import Image from 'views/partials/image'
import Link from 'views/partials/link'

// Deps
import { connect } from 'react-redux'
import userServices from 'services/user'
import extend from 'lodash/extend'
import { redirect } from 'controllers/navigator'

// Static Assets
import Logo from 'assets/images/logo.svg'

const mapStateToProps = state => {
	return {
		currentPage: state.site.currentPage,
		userData: state.user.data,
	};
};

const Header = ({ data, currentPage, userData, onBackClick }) => {
	const logoClicks = useRef(0);
	const logoClickTimer = useRef(false);
	const {prevRoute, prevRouteFallback, ...pageData} = useMemo(() => {
		let pageData = currentPage.data;
		if(data) {
			pageData = extend({}, pageData, data);
		}

		return pageData;
	}, [currentPage, data])

	const logoClick = () => {
		logoClicks.current++;

		if(logoClicks.current === 5) {
			logoClicks.current = 0;
			setTimeout(() => {
				redirect('keyListener');
			}, 500)
		}
		else {
			if(logoClickTimer.current) {
				clearTimeout(logoClickTimer.current);
			}
			logoClickTimer.current = setTimeout(() => {
				logoClicks.current = 0;
				logoClickTimer.current = false;
			}, 2000)
		}
	}
	
	return (
		<header className={"section header" + (pageData.plainHeader ? ' plain' : '')}>
			<div className="header-wrap wrapper">
				{prevRoute &&
					<>
						{prevRouteFallback && window.historyStates > 1 ?
							<button
								onClick={() => {
									window.history.back();
								}}
								className="header-prev">
								<i className="icon-angle-left"></i>
								</button>
							:
							<Link
								href={prevRoute}
								className="header-prev">
								<i className="icon-angle-left"></i>
							</Link>
						}
					</>
				}
				{onBackClick &&
					<button type="button" onClick={onBackClick} className="header-prev"><i className="icon-angle-left"></i></button>
				}
				{!pageData.plainHeader &&
					<Link href="dashboard" onClick={logoClick}>
						<Image src={Logo} className="header-logo" />
					</Link>
				}
				<h1 className="header-title">{pageData.headTitle ? pageData.headTitle : "Mağaza Operasyon Ekranı"}</h1>

				{userData && !pageData.plainHeader &&
					<React.Fragment>
						<div className="header-storeinfo">
							{userData.store_name} - {userData.store_id}
						</div>
						<div className="header-userbar">
							<i className="userbar-icon icon-user"></i>
							<p className="userbar-name">{userData.name}</p>
							<button className="userbar-logout" onClick={userServices.logout}>(çıkış yap)</button>
						</div>
					</React.Fragment>
				}
			</div>
		</header>
	);
}

export default connect(mapStateToProps)(Header);