import React, { useState, useRef, useCallback } from 'react';

// Sections
import BarcodeInput from 'views/sections/barcode-input'

const KeyListener = () => {
	// const _mounted = useRef(false);
	const pressEventsRef = useRef([]);
	const [pressEvents, setPressEventsRaw] = useState([])
	const [barcode, setBarcode] = useState(false);

	const setPressEvents = (keys) => {
		pressEventsRef.current = keys;
		setPressEventsRaw(keys);
	}

	const barcodeTrigger = useCallback((e) => {
		setPressEvents([
			e,
			...pressEventsRef.current
		]);
	}, []);

	const barcodeEntered = (barcode) => {
		setBarcode(barcode);
	}

	return (
		<div className="section key-listener">
			<div className="wrapper">
				<BarcodeInput
					className="listener-reader"
					autoClear
					noWrap
					onKeyEvent={barcodeTrigger}
					onSubmit={barcodeEntered} />
				{barcode &&
					<div className="listener-barcode">
						Son Okunan Barkod: <br />
						{barcode}
					</div>
				}
				<div className="listener-keys">
					{pressEvents.length > 0 ?
						<>
							{pressEvents.map((event, nth) => (
								<div className="keys-key" key={nth}>
									<div>event.key: "<strong>{event.key}</strong>"</div>
									<div>event.code: "<strong>{event.code}</strong>"</div>
									<div>event.keyCode: "<strong>{event.keyCode}</strong>"</div>
								</div>
							))}
						</>
						:
						<strong>Devam etmek için bir tuşa basın...</strong>
					}
				</div>
			</div>
		</div>
	)
}

export default KeyListener;