import config from "config/index"

const ua = window.navigator.userAgent.toLowerCase();

const initialState = {
	loading: false,
	currentPage: {
		key: false,
		data: {}
	},
	mobile: (window.innerWidth <= config.mobileBreakPoint),
	mobileBreakPoint: config.mobileBreakPoint,
	terminalDevice: ua.indexOf("android") > -1,
	scrollPos: 0,
	windowWidth: window.innerWidth,
	windowHeight: window.innerHeight,
	pageNotFound: true,
};

const siteReducer = (state = initialState, action) => {
	if (action.type === "SET_LOADING") {
		return Object.assign({}, state, {
			loading: action.payload
		});
	}
	else if (action.type === "SET_MOBILE") {
		return Object.assign({}, state, {
			mobile: action.payload
		});
	}
	else if (action.type === "SET_WW") {
		return Object.assign({}, state, {
			windowWidth: action.payload
		});
	}
	else if (action.type === "SET_WH") {
		return Object.assign({}, state, {
			windowHeight: action.payload
		});
	}
	else if (action.type === "SET_SCROLLPOS") {
		return Object.assign({}, state, {
			scrollPos: action.payload
		});
	}
	else if (action.type === "SET_PAGE") {
		return Object.assign({}, state, {
			currentPage: action.payload
		});
	}
	else if (action.type === "SET_PAGE_NOT_FOUND") {
		return Object.assign({}, state, {
			pageNotFound: action.payload
		});
	}
	else if (action.type === "SET_TERMINAL_DEVICE") {
		return Object.assign({}, state, {
			terminalDevice: action.payload
		});
	}
	return state;
};

export default siteReducer;