import React, { Component } from 'react'

// Controllers
import history from './utils/history'
import Navigator from './controllers/navigator'

// Deps
import { Router } from 'react-router-dom'
import userServices from 'services/user'
// import "@babel/polyfill";

class App extends Component {
	constructor(props) {
		super(props);

		userServices.checkLoginStatus().then(() => {

		}).catch(() => {
			
		})
	}

	render() {
		return (
			<Router history={history}>
				<Navigator />
			</Router>
		);
	}
}

export default App;
