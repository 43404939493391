import { useEffect, useRef } from 'react'

// Deps
// import * as Raven from 'utils/raven'
import * as Sentry from "@sentry/react";
import events from 'utils/events'
import config from 'config'
import { connect } from 'react-redux'
import omit from 'lodash/omit'
//import { logError } from 'utils/errors'

const mapStateToProps = state => {
	return {
		userData: state.user.data,
		currentPage: state.site.currentPage,
	};
};

// const handleWindowException = (error) => {

// };

const ErrorLogger = (props) => {
	const _initialized = useRef(false);

	const sendLogError = (event) => {
		if (_initialized.current) {
			Sentry.setTag("group", event.type);
			Sentry.setExtra('data', event.data);
			Sentry.captureMessage(event.message);
			Sentry.setTag("group", undefined);
			Sentry.setExtra('data', undefined);
		}
	}

	useEffect(() => {
		if (config.sentryDSN) {
			Sentry.init({
				release: config.environment + '@' + (process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION : "unspecified"),
				dsn: config.sentryDSN,
				maxBreadcrumbs: 50,
				environment: config.environment,
				// debug: config.environment !== 'production',
				tracesSampleRate: config.environment === 'production' ? 0.5 : 1.0,
				ignoreErrors: [
					'ResizeObserver loop limit exceeded',
					'ResizeObserver loop completed with undelivered notifications.',
				],
				denyUrls: [
					/extensions\//i,
					/^ chrome: \/\//i,
					/maps\.googleapis\.com/i,
				],
				// beforeSend: (event, hint) => {
				// 	return event;
				// },
			});
			Sentry.setTag("environment", config.environment);
			// Sentry.setTag("buildNumber", config.environment + '_' + (process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION : "unspecified"));

			events.bind('logError', sendLogError);

			_initialized.current = true;

			// Test Error
			// setTimeout(() => {
			// 	window.errorTest.errorTest2 = window.errorTest.errorTest;
			// }, 1000);
		}

		return (() => {
			events.unbind('logError', sendLogError);
		})
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (_initialized.current) {
			Sentry.setUser(props.userData ? omit(props.userData, ['mobile_number', 'new_mobile_number']) : false);
		}
	}, [props.userData])

	useEffect(() => {
		if (_initialized.current) {
			Sentry.setTag("page", props.currentPage ? props.currentPage.key : '');
		}
	}, [props.currentPage])

	return false;
}

export default connect(mapStateToProps)(ErrorLogger);